import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import { isEqual } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import React, { FC, useEffect, useMemo } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import {
	useEditProfileMutation,
	useEditSocietyConfigMutation,
	useGetSocietyConfigQuery
} from "../../redux/reducers/settings.reducer";
import { ISocietyConfigDetails } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux";
import { updateProfileDetails } from "../../redux/reducers/auth.reducer";
import { USER_ROLES_E } from "../../utils/constants";

interface IEditProfileDialogProps {
	open: boolean;
	onClose: () => void;
}

interface IEditProfileFormDetails {
	first_name: string;
	last_name: string;
	username: string;
	society_name: string;
}

const EditProfileDialog: FC<IEditProfileDialogProps> = (props) => {
	const { open, onClose } = props;

	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	// APIS
	// GET SOCIETY CONFIG
	const { data: getSocietyConfigResponse, refetch: refetchSocietyConfig } = useGetSocietyConfigQuery(null, {
		refetchOnMountOrArgChange: true
	});

	// EDIT PROFILE
	const [
		editProfile,
		{ isLoading: editProfileLoading, error: editProfileError, data: editProfileResponse, reset: resetEditProfileState }
	] = useEditProfileMutation();

	// EDIT SOCIETY CONFIG
	const [
		editSociety,
		{ isLoading: editSocietyLoading, error: editSocietyError, data: editSocietyResponse, reset: resetEditSocietyState }
	] = useEditSocietyConfigMutation();

	const societyDetails = useMemo<ISocietyConfigDetails | null>(() => {
		if (getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0) {
			return getSocietyConfigResponse.results[0];
		}

		return null;
	}, [getSocietyConfigResponse]);

	const {
		reset,
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm<IEditProfileFormDetails>();

	const firstName = watch("first_name");
	const lastName = watch("last_name");
	const username = watch("username");
	const societyName = watch("society_name");

	const saveButtonDisabled = useMemo<boolean>(() => {
		const currentProfileDetails: IEditProfileFormDetails = {
			first_name: firstName,
			last_name: lastName,
			username: username,
			society_name: societyName
		};

		const previousProfileDetails: IEditProfileFormDetails = {
			first_name: authState.user.first_name,
			last_name: authState.user.last_name,
			username: authState.user.username,
			society_name: societyDetails?.name ?? ""
		};

		return isEqual(currentProfileDetails, previousProfileDetails);
	}, [authState.user, firstName, lastName, societyDetails?.name, societyName, username]);

	function handleSubmitForm(formData: IEditProfileFormDetails) {
		const { first_name, last_name, username, society_name } = formData;

		editProfile({ first_name, last_name, username });

		if (societyDetails && societyDetails.id) {
			editSociety({
				id: societyDetails.id,
				data: { name: society_name }
			});
		}
	}

	useEffect(() => {
		if (open) {
			reset({
				first_name: authState.user.first_name,
				last_name: authState.user.last_name,
				username: authState.user.username,
				society_name: societyDetails?.name ?? ""
			});
		} else {
			reset({
				first_name: "",
				last_name: "",
				username: "",
				society_name: ""
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.user, open, societyDetails]);

	useEffect(() => {
		if (editProfileResponse && editSocietyResponse) {
			onClose();

			if (editProfileResponse) {
				resetEditProfileState();
				dispatch(
					updateProfileDetails({
						first_name: editProfileResponse.data.first_name,
						last_name: editProfileResponse.data.last_name,
						username: editProfileResponse.data.username
					})
				);
			}

			if (editSocietyResponse) {
				resetEditSocietyState();
				refetchSocietyConfig();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editProfileResponse, editSocietyResponse]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Edit Profile
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								error={!!errors.first_name}
								htmlFor="first-name-input"
								sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
							>
								First Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								color="primary"
								variant="filled"
								id="first-name-input"
								placeholder="Enter the first name"
								error={!!errors.first_name}
								helperText={errors.first_name && errors.first_name.message ? errors.first_name.message.toString() : ""}
								InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
								inputProps={{ style: { textTransform: "capitalize" } }}
								{...register("first_name", { required: "First name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								error={!!errors.last_name}
								htmlFor="last-name-input"
								sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
							>
								Last Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								color="primary"
								variant="filled"
								id="last-name-input"
								placeholder="Enter the last name"
								error={!!errors.last_name}
								helperText={errors.last_name && errors.last_name.message ? errors.last_name.message.toString() : ""}
								InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
								inputProps={{ style: { textTransform: "capitalize" } }}
								{...register("last_name")}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								error={!!errors.username}
								htmlFor="username-input"
								sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
							>
								Username
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								color="primary"
								variant="filled"
								id="username-input"
								placeholder="Enter the username"
								error={!!errors.username}
								helperText={errors.username && errors.username.message ? errors.username.message.toString() : ""}
								InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
								{...register("username", { required: "Username is required" })}
							/>
						</Grid>

						{authState.user.role !== USER_ROLES_E.SYSTEM_INTEGRATOR ? (
							<Grid item xs={12} md={6}>
								<InputLabel
									required
									error={!!errors.society_name}
									htmlFor="society-name-input"
									sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
								>
									Society Name
								</InputLabel>

								<TextField
									required
									fullWidth
									size="small"
									color="primary"
									variant="filled"
									id="society-name-input"
									placeholder="Enter the society name"
									error={!!errors.society_name}
									helperText={
										errors.society_name && errors.society_name.message ? errors.society_name.message.toString() : ""
									}
									InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
									inputProps={{ style: { textTransform: "capitalize" } }}
									{...register("society_name", { required: "Society name is required" })}
								/>
							</Grid>
						) : null}

						{editProfileError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)">
									{"data" in editProfileError && editProfileError.data
										? String(editProfileError.data)
										: "Failed to edit profile"}
								</Typography>
							</Grid>
						) : null}

						{editSocietyError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)">
									{"data" in editSocietyError && editSocietyError.data
										? String(editSocietyError.data)
										: "Failed to edit profile"}
								</Typography>
							</Grid>
						) : null}

						<Grid item xs={12}>
							<Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<LoadingButton
									disableElevation
									variant="contained"
									color="success"
									type="submit"
									startIcon={<SaveIcon />}
									loading={editProfileLoading || editSocietyLoading}
									disabled={saveButtonDisabled}
								>
									Save
								</LoadingButton>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default EditProfileDialog;
