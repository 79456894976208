import { Box, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { usePasswordResetMutation } from "../../redux/reducers/settings.reducer";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../redux";
import { showFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";

interface IForgotPasswordDialogProps {
	open: boolean;
	onClose: () => void;
}

interface IForgotPasswordInputs {
	username: string;
}

const ForgotPasswordDialog: FC<IForgotPasswordDialogProps> = (props) => {
	const { open, onClose } = props;

	const dispatch = useAppDispatch();

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IForgotPasswordInputs>();

	// API
	// PASSWORD RESET
	const [
		passwordReset,
		{
			isLoading: passwordResetLoading,
			error: passwordResetError,
			data: passwordResetResponse,
			reset: resetPasswordResetState
		}
	] = usePasswordResetMutation();

	function handleSubmitForm(formData: IForgotPasswordInputs) {
		passwordReset(formData.username);
	}

	useEffect(() => {
		if (open) {
			reset({ username: "" });
		} else {
			resetPasswordResetState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (passwordResetResponse) {
			onClose();
			dispatch(
				showFeedbackNotification({
					message: "Password reset link has been sent to your email.",
					severity: "success"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordResetResponse]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Forgot Password
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Box className="login-input-wrapper">
						<Typography variant="h6" fontWeight={400} color="#424242">
							Enter the username to reset password
						</Typography>

						<TextField
							required
							fullWidth
							autoFocus
							autoComplete="off"
							variant="filled"
							color="primary"
							placeholder="Enter username"
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
							error={!!errors.username}
							helperText={errors.username && errors.username.message ? errors.username.message.toString() : ""}
							{...register("username", { required: "Username is required" })}
						/>
					</Box>

					{passwordResetError ? (
						<Typography variant="body2" color="var(--color-error-main)" textAlign="center" marginTop="1rem">
							{"data" in passwordResetError && passwordResetError.data
								? String(passwordResetError.data)
								: "Something went wrong"}
						</Typography>
					) : null}

					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem" }}>
						<LoadingButton
							disableElevation
							variant="contained"
							color="success"
							type="submit"
							loading={passwordResetLoading}
						>
							Submit
						</LoadingButton>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ForgotPasswordDialog;
