import { IExcelValidationDetails } from "../types";

export const PAGINATION_CONSTANTS = {
	ROWS_PER_PAGE: 20
};

export const VALIDATION_REGEX = {
	PERSON_NAME: /^[a-z0-9 ,.'\-()/]+$/i,
	CONTACT_NUMBER: /^[6-9]{1}\d{9}$/,
	FLAT_NUMBER: /^[a-zA-Z0-9 ,*\-/]+$/,
	NUMBER_PLATE:
		/^([A-Za-z]{2}[0-9]{1,2}[A-Za-z]{1,3}[0-9]{2,4})|([0-9]{2}(bh|BH|bH|Bh)[0-9]{4}[A-Za-z]{1,2})|([A-Za-z]{2}[0-9]{6})|([A-Za-z]{3}[0-9]{4})/
};

export const API_POLLING_INTERVAL = {
	// IN SECONDS
	EVENT_LOGS: 60,
	DASHBOARD_NOTIFICATIONS: 30
};

export const BUILDING_CONFIG_CONSTANTS = {
	CUSTOM_ENTRY_BUILDING_NAME: "Common Area"
};

export const DATA_MANAGEMENT_CONSTANTS = {
	MEMBER_OWNER_TYPE_NAME: "Member",
	TENANT_OWNER_TYPE_NAME: "Tenant"
};

export const API_TIMESTAMP_FORMAT = {
	TIME_FILTER: "YYYY-MM-DD HH:mm:ss",
	TIME_FILTER_URL: "YYYY-MM-DD_HH-mm-ss"
};

export const SI_ACCESSIBLE_PAGES = ["/uptime", "/event-logs"];

/* ENUMS */
export enum VEHICLE_TYPE_E {
	FOUR_WHEELER = 0,
	TWO_WHEELER = 1,
	BUS = 2,
	TRUCK = 3,
	THREE_WHEELER = 4,
	OTHERS = 5
}

export enum BUILDING_TYPES_E {
	BUILDING = 0,
	CUSTOM_ENTRIES = 1
}

export enum CONFIG_MODE_E {
	GATE_GUARD = 0,
	ANPR = 1,
	MY_GATE = 3
}

export enum USER_ROLES_E {
	SYSTEM_INTEGRATOR = 2,
	TENANT = 3
}

export const DEFAULT_VALIDATIONS: IExcelValidationDetails = {
	name: {
		type: "char",
		strip: true,
		remove_whitespace: false,
		regex: VALIDATION_REGEX.PERSON_NAME,
		capitalize: false,
		title: true,
		lower: false,
		char_size: 160,
		allow_null: false
	},
	number_plate: {
		type: "char",
		strip: true,
		remove_whitespace: true,
		regex: VALIDATION_REGEX.FLAT_NUMBER,
		capitalize: true,
		title: true,
		lower: false,
		char_size: 17,
		allow_null: false
	},
	flat_number: {
		type: "char/dropdown",
		strip: true,
		remove_whitespace: false,
		regex: VALIDATION_REGEX.FLAT_NUMBER,
		capitalize: false,
		title: false,
		lower: false,
		char_size: 160,
		allow_null: false
	},
	start_date: {
		type: "date",
		strip: true,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	end_date: {
		type: "date",
		strip: true,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	vehicle_type: {
		type: "dropdown",
		strip: true,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	phone_number: {
		type: "char",
		strip: true,
		remove_whitespace: true,
		regex: VALIDATION_REGEX.CONTACT_NUMBER,
		capitalize: false,
		title: false,
		lower: false,
		char_size: 14,
		allow_null: false
	},
	auth_type: {
		type: "char",
		strip: true,
		remove_whitespace: true,
		regex: null,
		capitalize: false,
		title: false,
		lower: true,
		char_size: 150,
		allow_null: false
	},
	comment: {
		type: "char",
		strip: false,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: 1000,
		allow_null: false
	},
	is_custom_flat_number: {
		type: "bool",
		strip: false,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	is_valid: {
		type: "bool",
		strip: false,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	}
};
