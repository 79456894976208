import { Close, Download } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import { useGetSocietyReportsQuery } from "../../../redux/reducers/settings.reducer";
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams } from "@mui/x-data-grid";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import moment from "moment";
import { PAGINATION_CONSTANTS } from "../../../utils/constants";
import TablePagination from "../../../components/TablePagination";
import { orderBy } from "lodash";
import downloadServices from "../../../redux/services/download.services";

interface IReportHistoryDialogProps {
	open: boolean;
	societyId: number;
	onClose: () => void;
}

interface ISocietyReportDetails {
	id: number;
	file_name: string;
	size: string;
	society_uuid: string;
}

const ReportHistoryPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const ReportHistoryDialog: FC<IReportHistoryDialogProps> = (props) => {
	const { open, societyId, onClose } = props;

	const [showDownloadLoader, setShowDownloadLoader] = useState<number[]>([]);

	// APIS
	// GET SOCIETY REPORTS
	const {
		data: getSocietyReportsResponse,
		isLoading: getSocietyReportsLoading,
		isFetching: getSocietyReportsFetching
	} = useGetSocietyReportsQuery(societyId, { skip: societyId < 0 });

	const rowsData = useMemo<ISocietyReportDetails[]>(() => {
		if (getSocietyReportsResponse) {
			return orderBy(getSocietyReportsResponse.reports, "file_name", "desc").map((reportItem, index) => ({
				id: index,
				file_name: reportItem.file_name,
				size: reportItem.file_size,
				society_uuid: reportItem.society_uuid
			}));
		}

		return [];
	}, [getSocietyReportsResponse]);

	function handleDownloadButtonClick(reportDetails: ISocietyReportDetails) {
		setShowDownloadLoader((currentLoaders) => {
			if (!currentLoaders.includes(reportDetails.id)) {
				return [...currentLoaders, reportDetails.id];
			}

			return currentLoaders;
		});

		downloadServices
			.handleDownloadSocietyReport({
				society_uuid: reportDetails.society_uuid,
				file_name: reportDetails.file_name
			})
			.catch((error) => {
				console.error("Download failed ::", error);
			})
			.finally(() => {
				setShowDownloadLoader((currentLoaders) => {
					return currentLoaders.filter((loaderId) => loaderId !== reportDetails.id);
				});
			});
	}

	const columnsData: GridColDef[] = [
		{
			field: "file_name",
			headerName: "Date",
			flex: 1,
			minWidth: 100,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<string>) => {
				const dateMatch = params.value.match(/_\d{4}-\d{2}-\d{2}\.pdf$/);
				const date = dateMatch ? dateMatch[0].slice(1, -4) : "";

				return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
			}
		},
		{
			field: "size",
			headerName: "PDF Size",
			flex: 2,
			minWidth: 200,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ISocietyReportDetails>) => (
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
					{showDownloadLoader.includes(params.row.id) ? (
						<CircularProgress size={16} />
					) : (
						<IconButton size="small" color="primary" onClick={() => handleDownloadButtonClick(params.row)}>
							<Download fontSize="inherit" />
						</IconButton>
					)}
				</Box>
			)
		}
	];

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Reporting History
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<Close color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<DataGrid
					columns={columnsData}
					rows={rowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					pageSizeOptions={[PAGINATION_CONSTANTS.ROWS_PER_PAGE]}
					slots={{ pagination: ReportHistoryPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					loading={getSocietyReportsLoading || getSocietyReportsFetching}
					hideFooter={rowsData.length <= PAGINATION_CONSTANTS.ROWS_PER_PAGE}
					classes={{
						columnSeparator: "communication-channels-table-column-separator",
						columnHeader: "communication-channels-table-column-header"
					}}
					sx={{ minHeight: 78 }}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default ReportHistoryDialog;
