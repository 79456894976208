import { Box, Button } from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import ConstructionIcon from "@mui/icons-material/Construction";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import "./societyConfiguration.css";
import BuildingConfigurationComponent from "./BuildingConfigurationComponent";
import CustomAreasComponent from "./CustomAreasComponent";
import { useEditSocietyConfigMutation, useGetSocietyConfigQuery } from "../../../redux/reducers/settings.reducer";
import { IBuildingDetails, IEditBuildingConfigurationDetails, ISocietyConfigDetails } from "../../../types";
import { BUILDING_TYPES_E, BUILDING_CONFIG_CONSTANTS } from "../../../utils/constants";
import { useAppDispatch } from "../../../redux";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";

type SocietyConfigurationSelectedTabType = "building_config" | "custom_area";

const SocietyConfiguration: FC = () => {
	const dispatch = useAppDispatch();

	const [selectedTab, setSelectedTab] = useState<SocietyConfigurationSelectedTabType>("building_config");
	const [buildingsList, setBuildingsList] = useState<IBuildingDetails[]>([]);
	const [customEntriesList, setCustomEntriesList] = useState<string[]>([]);

	const [initialBuildingsList, setInitialBuildingsList] = useState<IBuildingDetails[]>([]);
	const [initialCustomEntriesList, setInitialCustomEntriesList] = useState<string[]>([]);

	// APIS
	const [
		editBuildingConfiguration,
		{
			isLoading: editBuildingConfigLoading,
			isSuccess: editBuildingConfigSuccess,
			error: editBuildingConfigError,
			reset: resetEditBuildingConfigState
		}
	] = useEditSocietyConfigMutation();

	const { data: getSocietyConfigResponse, refetch: refetchSocietyConfig } = useGetSocietyConfigQuery();
	const societyDetails: ISocietyConfigDetails | null =
		getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
			? getSocietyConfigResponse.results[0]
			: null;

	function handleChangeSelectedTab(newTab: SocietyConfigurationSelectedTabType) {
		setSelectedTab(newTab);
	}

	function handleSubmitBuildingConfiguration(updatedData?: { buildings?: IBuildingDetails[]; customArea?: string[] }) {
		if (!societyDetails) return;

		const finalBuildingConfig: IEditBuildingConfigurationDetails[] = [];

		if (updatedData && updatedData.buildings) {
			for (const buildingItem of updatedData.buildings) {
				finalBuildingConfig.push({
					name: buildingItem.name,
					building_type: buildingItem.building_type,
					entries: buildingItem.entries.map((entryItem) =>
						entryItem.replace(buildingItem.name, "").replace(buildingItem.delimiter, "")
					),
					floors: buildingItem.number_of_floors,
					flats: buildingItem.flats_per_floor,
					delimiter: buildingItem.delimiter,
					show_floor_number: buildingItem.show_floor_number
				});
			}
		} else {
			for (const buildingItem of buildingsList) {
				finalBuildingConfig.push({
					name: buildingItem.name,
					building_type: buildingItem.building_type,
					entries: buildingItem.entries.map((entryItem) =>
						entryItem.replace(buildingItem.name, "").replace(buildingItem.delimiter, "")
					),
					floors: buildingItem.number_of_floors,
					flats: buildingItem.flats_per_floor,
					delimiter: buildingItem.delimiter,
					show_floor_number: buildingItem.show_floor_number
				});
			}
		}

		if (updatedData && updatedData.customArea) {
			if (updatedData.customArea.length > 0) {
				finalBuildingConfig.push({
					name: BUILDING_CONFIG_CONSTANTS.CUSTOM_ENTRY_BUILDING_NAME,
					building_type: BUILDING_TYPES_E.CUSTOM_ENTRIES,
					entries: updatedData.customArea
				});
			}
		} else {
			if (customEntriesList.length > 0) {
				finalBuildingConfig.push({
					name: BUILDING_CONFIG_CONSTANTS.CUSTOM_ENTRY_BUILDING_NAME,
					building_type: BUILDING_TYPES_E.CUSTOM_ENTRIES,
					entries: customEntriesList
				});
			}
		}

		if (finalBuildingConfig.length > 0) {
			editBuildingConfiguration({
				id: societyDetails.id,
				data: {
					building_config: finalBuildingConfig
				}
			});
		}
	}

	function handleDeleteBuilding(index: number) {
		if (index >= 0) {
			const updatedBuildingsList = [...buildingsList];
			updatedBuildingsList.splice(index, 1);
			setBuildingsList(updatedBuildingsList);

			handleSubmitBuildingConfiguration({ buildings: updatedBuildingsList });
		}
	}

	function handleSaveBuildingDetails(index: number, updatedBuildingDetails: IBuildingDetails) {
		if (index >= 0) {
			const updatedBuildingsList = [...buildingsList];
			updatedBuildingsList[index] = updatedBuildingDetails;
			setBuildingsList(updatedBuildingsList);

			handleSubmitBuildingConfiguration({ buildings: updatedBuildingsList });
		}
	}

	function handleAddNewBuilding() {
		const updatedBuildingsList = [...buildingsList];

		const updatedLength = updatedBuildingsList.push({
			name: "New Building",
			building_type: 0,
			entries: [],
			number_of_floors: "",
			flats_per_floor: "",
			new_building: true,
			delimiter: "",
			show_floor_number: true
		});

		setBuildingsList(updatedBuildingsList);

		return updatedLength - 1;
	}

	function handleUpdateCustomEntries(updatedEntries: string[]) {
		setCustomEntriesList(updatedEntries);
	}

	function handleSubmitCustomEntries() {
		handleSubmitBuildingConfiguration();
	}

	useEffect(() => {
		if (societyDetails) {
			const updatedBuildingsList: IBuildingDetails[] = [];
			const updatedCustomEntriesList: string[] = [];

			const updatedInitialBuildingsList: IBuildingDetails[] = [];
			const updatedInitialCustomEntriesList: string[] = [];

			for (const buildingItem of societyDetails.building_config) {
				if (buildingItem.building_type === BUILDING_TYPES_E.BUILDING) {
					const buildingDetails: IBuildingDetails = {
						name: buildingItem.name,
						building_type: buildingItem.building_type,
						entries: buildingItem.entries.map(
							(entryItem) => `${buildingItem.name}${buildingItem.delimiter ?? ""}${entryItem}`
						),
						number_of_floors: buildingItem.floors ?? "",
						flats_per_floor: buildingItem.flats ?? "",
						new_building: false,
						delimiter: buildingItem.delimiter ?? "",
						show_floor_number: buildingItem.show_floor_number ?? false
					};

					updatedBuildingsList.push(buildingDetails);
					updatedInitialBuildingsList.push(buildingDetails);
				} else if (buildingItem.building_type === BUILDING_TYPES_E.CUSTOM_ENTRIES) {
					updatedCustomEntriesList.push(...buildingItem.entries);
					updatedInitialCustomEntriesList.push(...buildingItem.entries);
				}
			}

			setBuildingsList(updatedBuildingsList);
			setCustomEntriesList(updatedCustomEntriesList);

			setInitialBuildingsList([...updatedInitialBuildingsList]);
			setInitialCustomEntriesList([...updatedInitialCustomEntriesList]);
		}
	}, [societyDetails]);

	useEffect(() => {
		if (editBuildingConfigSuccess) {
			resetEditBuildingConfigState();
			refetchSocietyConfig();
			dispatch(
				showFeedbackNotification({
					message: "Property Configuration updated successfully",
					severity: "success"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editBuildingConfigSuccess]);

	useEffect(() => {
		if (editBuildingConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editBuildingConfigError && editBuildingConfigError.data
							? String(editBuildingConfigError.data)
							: "Failed to save property configuration",
					severity: "error"
				})
			);

			resetEditBuildingConfigState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editBuildingConfigError]);

	const RenderSelectedTabContent: { [key in SocietyConfigurationSelectedTabType]: ReactNode } = {
		building_config: (
			<BuildingConfigurationComponent
				buildingsList={buildingsList}
				initialBuildingsList={initialBuildingsList}
				saveButtonLoading={editBuildingConfigLoading}
				onDeleteBuilding={handleDeleteBuilding}
				onSaveBuilding={handleSaveBuildingDetails}
				onAddBuilding={handleAddNewBuilding}
			/>
		),
		custom_area: (
			<CustomAreasComponent
				customAreasList={customEntriesList}
				initialCustomAreasList={initialCustomEntriesList}
				saveButtonLoading={editBuildingConfigLoading}
				onUpdateCustomEntries={handleUpdateCustomEntries}
				onSave={handleSubmitCustomEntries}
			/>
		)
	};

	return (
		<Box className="society-configuration-screen-wrapper">
			<Box className="society-configuration-screen-tabs-wrapper">
				<Button
					disableElevation
					color="primary"
					variant={selectedTab === "building_config" ? "contained" : "outlined"}
					startIcon={<ConstructionIcon />}
					onClick={() => handleChangeSelectedTab("building_config")}
				>
					Building Configuration
				</Button>

				<Button
					disableElevation
					color="primary"
					variant={selectedTab === "custom_area" ? "contained" : "outlined"}
					startIcon={<DashboardCustomizeIcon />}
					sx={{ paddingX: 4 }}
					onClick={() => handleChangeSelectedTab("custom_area")}
				>
					Custom Areas
				</Button>
			</Box>

			{RenderSelectedTabContent[selectedTab]}
		</Box>
	);
};

export default SocietyConfiguration;
