import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import "./deviceSelection.css";
import { useAppSelector } from "../../redux";
import { USER_ROLES_E } from "../../utils/constants";
import { IGetAllDevicesListResponseData, IGetAllSocietiesResponseData } from "../../types";
import { useGetAllDevicesListQuery, useGetAllSocietiesListQuery } from "../../redux/reducers/settings.reducer";

interface IDeviceSelectionProps {
	colorMode?: "light" | "dark";
	disableAllSelected?: boolean;
	defaultValues?: number[];
	multiple?: boolean;
	onChange: (deviceIds: number[]) => void;
}

const DeviceSelection: FC<IDeviceSelectionProps> = (props) => {
	const { colorMode = "light", disableAllSelected, multiple, onChange } = props;

	const authState = useAppSelector((state) => state.auth);

	const societyDropdownAnchorRef = useRef<HTMLDivElement>(null);
	const deviceDropdownAnchorRef = useRef<HTMLDivElement>(null);

	const [societyDropdownOpen, setSocietyDropdownOpen] = useState<boolean>(false);
	const [deviceDropdownOpen, setDeviceDropdownOpen] = useState<boolean>(false);

	const [selectedSocieties, setSelectedSocieties] = useState<IGetAllSocietiesResponseData[]>([]);
	const [finalSelectedSocieties, setFinalSelectedSocieties] = useState<IGetAllSocietiesResponseData[]>([]);
	const [societyError, setSocietyError] = useState<string>("");

	const [selectedDevices, setSelectedDevices] = useState<IGetAllDevicesListResponseData[]>([]);
	const [finalSelectedDevices, setFinalSelectedDevices] = useState<IGetAllDevicesListResponseData[]>([]);
	const [deviceError, setDeviceError] = useState<string>("");

	// APIS
	// GET ALL SOCIETIES LIST
	const { data: getSocietysResponse } = useGetAllSocietiesListQuery();

	// GET ALL DEVICES LIST
	const { data: getDevicesListResponse } = useGetAllDevicesListQuery();

	const allSocietiesList = useMemo<IGetAllSocietiesResponseData[]>(() => {
		if (getSocietysResponse) return getSocietysResponse;
		return [];
	}, [getSocietysResponse]);

	const selectedSocietyIds = useMemo<number[]>(() => {
		return selectedSocieties.map((societyItem) => societyItem.id);
	}, [selectedSocieties]);

	const allDevicesList = useMemo<IGetAllDevicesListResponseData[]>(() => {
		if (!getDevicesListResponse) return [];

		if (finalSelectedSocieties.length > 0) {
			return getDevicesListResponse.filter((deviceItem) =>
				finalSelectedSocieties.some((societyItem) => societyItem.id === deviceItem.society_id)
			);
		}

		return getDevicesListResponse;
	}, [finalSelectedSocieties, getDevicesListResponse]);

	const selectedDeviceIds = useMemo<number[]>(() => {
		return selectedDevices.map((deviceItem) => deviceItem.id);
	}, [selectedDevices]);

	function handleChangeDevice(deviceIds: number[]) {
		if (getDevicesListResponse && deviceIds.length === getDevicesListResponse.length) {
			onChange([]);
		} else {
			onChange(deviceIds);
		}
	}

	function handleOpenSocietyDropdown() {
		if (allSocietiesList.length > 1) {
			setSelectedSocieties(finalSelectedSocieties);
			setSocietyDropdownOpen(true);
		}
	}

	function handleCloseSocietyDropdown(finalList?: IGetAllSocietiesResponseData[]) {
		setSocietyDropdownOpen(false);
		setSelectedSocieties([]);
		if (finalList) setFinalSelectedSocieties(finalList);
	}

	function handleSocietyItemClick(societyDetails?: IGetAllSocietiesResponseData) {
		if (multiple) {
			let updatedSocieties = [...selectedSocieties];

			if (societyDetails) {
				if (updatedSocieties.some((item) => item.id === societyDetails.id)) {
					// CLICKED SOCIETY ALREADY SELECTED
					updatedSocieties = updatedSocieties.filter((item) => item.id !== societyDetails.id);
				} else {
					// CLICKED SOCIETY NOT ALREADY SELECTED
					updatedSocieties.push(societyDetails);
				}
			} else {
				// SELECT ALL CLICKED
				if (updatedSocieties.length === allSocietiesList.length) updatedSocieties = [];
				else updatedSocieties = [...allSocietiesList];
			}

			setSelectedSocieties(updatedSocieties);
		} else {
			if (societyDetails) {
				handleCloseSocietyDropdown([societyDetails]);
			} else {
				handleCloseSocietyDropdown([]);
			}
		}
	}

	function handleSocietyApplyButtonClick() {
		if (selectedSocieties.length <= 0) {
			setSocietyError("At least one property should be selected");
		} else {
			handleCloseSocietyDropdown(selectedSocieties);
		}
	}

	function handleOpenDeviceDropdown() {
		if (allDevicesList.length > 1) {
			setSelectedDevices(finalSelectedDevices);
			setDeviceDropdownOpen(true);
		}
	}

	function handleCloseDeviceDropdown(finalList?: IGetAllDevicesListResponseData[]) {
		setDeviceDropdownOpen(false);
		setSelectedDevices([]);
		if (finalList) {
			setFinalSelectedDevices(finalList);
			handleChangeDevice(finalList.map((item) => item.id));
		}
	}

	function handleDeviceItemClick(deviceDetails?: IGetAllDevicesListResponseData) {
		if (multiple) {
			let updatedDevices = [...selectedDevices];

			if (deviceDetails) {
				if (updatedDevices.some((item) => item.id === deviceDetails.id)) {
					// CLICKED DEVICE ALREADY SELECTED
					updatedDevices = updatedDevices.filter((item) => item.id !== deviceDetails.id);
				} else {
					// CLICKED DEVICE NOT ALREADY SELECTED
					updatedDevices.push(deviceDetails);
				}
			} else {
				// SELECT ALL CLICKED
				if (updatedDevices.length === allDevicesList.length) updatedDevices = [];
				else updatedDevices = [...allDevicesList];
			}

			setSelectedDevices(updatedDevices);
		} else {
			if (deviceDetails) {
				handleCloseDeviceDropdown([deviceDetails]);
			} else {
				handleCloseDeviceDropdown([]);
			}
		}
	}

	function handleDeviceApplyButtonClick() {
		if (selectedDevices.length <= 0) {
			setSocietyError("At lease one device should be selected");
		} else {
			handleCloseDeviceDropdown(selectedDevices);
		}
	}

	useEffect(() => {
		if (!societyDropdownOpen) {
			setSocietyError("");
		}
	}, [societyDropdownOpen]);

	useEffect(() => {
		if (!deviceDropdownOpen) {
			setDeviceError("");
		}
	}, [deviceDropdownOpen]);

	useEffect(() => {
		if (multiple) {
			setFinalSelectedSocieties(allSocietiesList);
		} else {
			setFinalSelectedSocieties(allSocietiesList.length > 0 && disableAllSelected ? [allSocietiesList[0]] : []);
		}
	}, [allSocietiesList, disableAllSelected, multiple]);

	useEffect(() => {
		if (multiple) {
			setFinalSelectedDevices(allDevicesList);
			handleChangeDevice(allDevicesList.map((item) => item.id));
		} else {
			if (disableAllSelected && allDevicesList.length > 0) {
				setFinalSelectedDevices([allDevicesList[0]]);
				handleChangeDevice([allDevicesList[0].id]);
			} else {
				setFinalSelectedDevices([]);
				handleChangeDevice([]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allDevicesList, disableAllSelected, multiple]);

	return (
		<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
			{authState.user.role === USER_ROLES_E.SYSTEM_INTEGRATOR ? (
				<Box
					component="div"
					className={`device-selection-button ${colorMode}`}
					ref={societyDropdownAnchorRef}
					aria-haspopup
					id="property-selection-dropdown-button"
					onClick={handleOpenSocietyDropdown}
					aria-expanded={societyDropdownOpen}
					aria-controls="property-selection-dropdown-menu"
				>
					<Box className="device-selection-button-text">
						<ApartmentIcon color="inherit" fontSize="small" />
						<Typography fontWeight={500}>
							{multiple ? "Property" : finalSelectedSocieties.length > 0 ? finalSelectedSocieties[0].name : "Property"}
						</Typography>
					</Box>

					{allSocietiesList.length > 1 ? (
						societyDropdownOpen ? (
							<ArrowDropUpIcon color="inherit" fontSize="small" />
						) : (
							<ArrowDropDownIcon color="inherit" fontSize="small" />
						)
					) : null}
				</Box>
			) : null}

			{authState.user.role === USER_ROLES_E.SYSTEM_INTEGRATOR && allSocietiesList.length > 1 ? (
				<Menu
					disableAutoFocusItem
					id="property-selection-dropdown-menu"
					anchorEl={societyDropdownAnchorRef.current}
					open={societyDropdownOpen}
					onClose={() => handleCloseSocietyDropdown()}
					MenuListProps={{ disablePadding: true, "aria-labelledby": "property-selection-dropdown-button" }}
					slotProps={{ paper: { elevation: 1 } }}
				>
					{!disableAllSelected ? (
						<MenuItem
							divider
							selected={
								!multiple &&
								(finalSelectedSocieties.length === 0 || finalSelectedSocieties.length === allSocietiesList.length)
							}
							onClick={() => handleSocietyItemClick()}
							classes={{ root: "device-selection-dropdown-menu-item", selected: "device-item-selected" }}
						>
							{multiple ? (
								<ListItemIcon>
									{selectedSocieties.length === allSocietiesList.length ? (
										<CheckBoxIcon color="primary" />
									) : selectedSocieties.length === 0 ? (
										<CheckBoxOutlineBlankIcon color="primary" />
									) : (
										<IndeterminateCheckBoxIcon color="primary" />
									)}
								</ListItemIcon>
							) : null}
							All Properties
						</MenuItem>
					) : null}

					{allSocietiesList.map((societyItem) => (
						<MenuItem
							divider
							selected={!multiple && selectedSocietyIds.includes(societyItem.id)}
							key={societyItem.id}
							onClick={() => handleSocietyItemClick(societyItem)}
							classes={{ root: "device-selection-dropdown-menu-item", selected: "device-item-selected" }}
						>
							{multiple ? (
								<ListItemIcon>
									{selectedSocietyIds.includes(societyItem.id) ? (
										<CheckBoxIcon color="primary" />
									) : (
										<CheckBoxOutlineBlankIcon color="primary" />
									)}
								</ListItemIcon>
							) : null}

							{societyItem.name}
						</MenuItem>
					))}

					{multiple ? (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								padding: "0.25rem 0.5rem"
							}}
						>
							{societyError ? (
								<Typography variant="body2" color="var(--color-error-main)">
									{societyError}
								</Typography>
							) : null}

							<Button disableElevation color="primary" onClick={handleSocietyApplyButtonClick}>
								Apply
							</Button>
						</Box>
					) : null}
				</Menu>
			) : null}

			<Box
				component="div"
				className={`device-selection-button ${colorMode}`}
				ref={deviceDropdownAnchorRef}
				aria-haspopup="true"
				id="device-selection-dropdown-button"
				onClick={handleOpenDeviceDropdown}
				aria-expanded={deviceDropdownOpen}
				aria-controls="device-selection-dropdown-menu"
			>
				<Box className="device-selection-button-text">
					<DeveloperBoardIcon color="inherit" fontSize="small" />
					<Typography fontWeight={500}>
						{multiple
							? finalSelectedDevices.length === 1
								? finalSelectedDevices[0].device_name
								: "Devices"
							: finalSelectedDevices.length > 0
							? finalSelectedDevices[0].device_name
							: disableAllSelected
							? "Device"
							: "All Devices"}
					</Typography>
				</Box>

				{allDevicesList.length > 1 ? (
					deviceDropdownOpen ? (
						<ArrowDropUpIcon color="inherit" fontSize="small" />
					) : (
						<ArrowDropDownIcon color="inherit" fontSize="small" />
					)
				) : null}
			</Box>

			{allDevicesList.length > 1 ? (
				<Menu
					disableAutoFocusItem
					id="device-selection-dropdown-menu"
					anchorEl={deviceDropdownAnchorRef.current}
					open={deviceDropdownOpen}
					onClose={() => handleCloseDeviceDropdown()}
					MenuListProps={{ disablePadding: true, "aria-labelledby": "device-selection-dropdown-button" }}
					slotProps={{ paper: { elevation: 1 } }}
				>
					{!disableAllSelected ? (
						<MenuItem
							divider
							selected={
								!multiple &&
								(finalSelectedDevices.length === 0 || finalSelectedDevices.length === allDevicesList.length)
							}
							onClick={() => handleDeviceItemClick()}
							classes={{ root: "device-selection-dropdown-menu-item", selected: "device-item-selected" }}
						>
							{multiple ? (
								<ListItemIcon>
									{selectedDevices.length === allDevicesList.length ? (
										<CheckBoxIcon color="primary" />
									) : selectedDevices.length === 0 ? (
										<CheckBoxOutlineBlankIcon color="primary" />
									) : (
										<IndeterminateCheckBoxIcon color="primary" />
									)}
								</ListItemIcon>
							) : null}
							All Devices
						</MenuItem>
					) : null}

					{allDevicesList.map((deviceItem) => (
						<MenuItem
							divider
							selected={!multiple && selectedDeviceIds.includes(deviceItem.id)}
							key={deviceItem.id}
							onClick={() => handleDeviceItemClick(deviceItem)}
							classes={{ root: "device-selection-dropdown-menu-item", selected: "device-item-selected" }}
						>
							{multiple ? (
								<ListItemIcon>
									{selectedDeviceIds.includes(deviceItem.id) ? (
										<CheckBoxIcon color="primary" />
									) : (
										<CheckBoxOutlineBlankIcon color="primary" />
									)}
								</ListItemIcon>
							) : null}

							{deviceItem.device_name}
						</MenuItem>
					))}

					{multiple ? (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								padding: "0.25rem 0.5rem"
							}}
						>
							{deviceError ? (
								<Typography variant="body2" color="var(--color-error-main)">
									{deviceError}
								</Typography>
							) : null}

							<Button disableElevation color="primary" onClick={handleDeviceApplyButtonClick}>
								Apply
							</Button>
						</Box>
					) : null}
				</Menu>
			) : null}
		</Box>
	);
};

export default DeviceSelection;
