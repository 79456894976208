import {
	GridCellParams,
	GridColDef,
	GridEditInputCell,
	GridPreProcessEditCellProps,
	GridRenderCellParams,
	GridRenderEditCellParams,
	GridValueGetterParams
} from "@mui/x-data-grid";
import moment from "moment";
import { read, utils } from "xlsx";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import SearchIcon from "@mui/icons-material/Search";

import {
	CombinedRowErrorFieldType,
	CombinedRowType,
	DataSet,
	IExcelValidationDetails,
	IListsData,
	RowCol
} from "../types";
import { convertToTitleCase } from "./commonUtils";

export const DUPLICATE_VEHICLE_NUMBER_ERROR_MESSAGE = "Vehicle with this number already exists";

const INVALID_FLAT_NUMBER_ERROR_MESSAGE = "Flat number is not valid";
const EMPTY_FLAT_NUMBER_ERROR_MESSAGE = "Flat number cannot be empty";
const INVALID_VEHICLE_NUMBER_ERROR_MESSAGE = "Vehicle number is not valid";
const INVALID_CONTACT_NUMBER_ERROR_MESSAGE = "Contact number is not valid";
const INVALID_VEHICLE_TYPE_ERROR_MESSAGE = "Vehicle type is not valid";
const INVALID_OWNER_NAME_ERROR_MESSAGE = "Vehicle owner name is not valid (Name should not contain special characters)";
const INVALID_OWNER_TYPE_ERROR_MESSAGE = "Owner type is not valid";
const INVALID_START_DATE_ERROR_MESSAGE = "Validity start date is not valid";
const INVALID_END_DATE_ERROR_MESSAGE = "Validity end date is not valid";

function getCharLimitErrorMessage(limit: number, key: "owner_name" | "flat_number"): string {
	if (key === "owner_name") return `Owner name cannot have more than ${limit} characters`;
	if (key === "flat_number") return `Flat number cannot have more than ${limit} characters`;
	return "";
}

const NO_ROW_ISSUES_MESSAGE = "No issues found";

const EXCEL_CELL_EMPTY_PLACEHOLDER = "---";

const VALIDITY_DATE_COMPATIBLE_FORMATS = ["MM/DD/YY", "YYYY-MM-DD", "DD/MM/YYYY"];

function EditInputCell(props: GridRenderEditCellParams) {
	return <GridEditInputCell {...props} />;
}

function getCellClassName(
	params: GridCellParams<CombinedRowType>,
	columnKey: keyof CombinedRowType,
	initialClassName?: string
): string {
	let className = "";
	const errorClassName = "input-error";

	if (columnKey !== "id" && columnKey !== "sr_no") {
		if (initialClassName) className = `${initialClassName} `;

		if (columnKey === "errorFields") {
			className = `${className}${
				params.row.errorFields && Object.keys(params.row.errorFields).length > 0 ? errorClassName : ""
			}`;
		} else {
			className = `${className}${params.row.errorFields && params.row.errorFields[columnKey] ? errorClassName : ""}`;
		}
	}

	return className;
}

function getColumnDef(
	columnKey: keyof CombinedRowType,
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean,
	openErrorDialogCallback: (errorMessage: string) => void,
	openColumnFilterCallback: (anchorElement: HTMLDivElement, columnName: string) => void
): GridColDef | null {
	switch (columnKey) {
		case "Vehicle Owner Name":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "string",
				cellClassName: (params) => getCellClassName(params, columnKey),
				renderHeader: () => (
					<Box component="div" className="upload-data-column-filter-wrapper">
						<Typography variant="subtitle2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
							{columnKey}
						</Typography>

						<IconButton
							size="small"
							onClick={(event) => {
								openColumnFilterCallback(event.currentTarget.parentElement as HTMLDivElement, columnKey);
							}}
						>
							<SearchIcon fontSize="small" />
						</IconButton>
					</Box>
				)
			};

		case "Flat Number":
			if (customFlatSelectionEnabled) {
				return {
					field: columnKey,
					flex: 1,
					editable: true,
					sortable: false,
					cellClassName: (params) => getCellClassName(params, columnKey),
					renderHeader: () => (
						<Box className="upload-data-column-filter-wrapper">
							<Typography variant="subtitle2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
								{columnKey}
							</Typography>

							<IconButton
								size="small"
								onClick={(event) => {
									openColumnFilterCallback(event.currentTarget.parentElement as HTMLDivElement, columnKey);
								}}
							>
								<SearchIcon fontSize="small" />
							</IconButton>
						</Box>
					)
				};
			}

			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "singleSelect",
				valueOptions: listsData.flatsList,
				preProcessEditCellProps: (params: GridPreProcessEditCellProps<string>) => {
					const hasError = !listsData.flatsList.includes(params.props.value ?? "");
					return { ...params.props, error: hasError };
				},
				cellClassName: (params) => getCellClassName(params, columnKey),
				renderHeader: () => (
					<Box className="upload-data-column-filter-wrapper">
						<Typography variant="subtitle2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
							{columnKey}
						</Typography>

						<IconButton
							size="small"
							onClick={(event) => {
								openColumnFilterCallback(event.currentTarget.parentElement as HTMLDivElement, columnKey);
							}}
						>
							<SearchIcon fontSize="small" />
						</IconButton>
					</Box>
				)
			};

		case "Number Plate":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "string",
				preProcessEditCellProps: (params: GridPreProcessEditCellProps<string>) => {
					const hasError =
						validations.number_plate.regex && !validations.number_plate.regex.test(params.props.value ?? "");
					return { ...params.props, error: hasError };
				},
				renderEditCell: EditInputCell,
				cellClassName: (params) => getCellClassName(params, columnKey, "number-plate"),
				renderHeader: () => (
					<Box className="upload-data-column-filter-wrapper">
						<Typography variant="subtitle2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
							{columnKey}
						</Typography>

						<IconButton
							size="small"
							onClick={(event) => {
								openColumnFilterCallback(event.currentTarget.parentElement as HTMLDivElement, columnKey);
							}}
						>
							<SearchIcon fontSize="small" />
						</IconButton>
					</Box>
				)
			};

		case "Vehicle Type":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "singleSelect",
				valueOptions: listsData.vehicleTypesList.map((vehicleTypeItem) => vehicleTypeItem.name),
				cellClassName: (params) => getCellClassName(params, columnKey)
			};

		case "Owner Type":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "singleSelect",
				valueOptions: listsData.ownerTypesList.map((ownerTypeItem) => ownerTypeItem.type_of),
				cellClassName: (params) => getCellClassName(params, columnKey)
			};

		case "Contact Number":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "string",
				headerAlign: "left",
				align: "left",
				renderEditCell: EditInputCell,
				preProcessEditCellProps: (params: GridPreProcessEditCellProps<string>) => {
					const hasError =
						validations.phone_number.regex && !validations.phone_number.regex.test(params.props.value ?? "");
					return { ...params.props, error: hasError };
				},
				cellClassName: (params) => getCellClassName(params, columnKey),
				renderHeader: () => (
					<Box className="upload-data-column-filter-wrapper">
						<Typography variant="subtitle2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
							{columnKey}
						</Typography>

						<IconButton
							size="small"
							onClick={(event) => {
								openColumnFilterCallback(event.currentTarget.parentElement as HTMLDivElement, columnKey);
							}}
						>
							<SearchIcon fontSize="small" />
						</IconButton>
					</Box>
				)
			};

		case "Validity Start Date":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "date",
				cellClassName: (params) => getCellClassName(params, columnKey)
			};

		case "Validity End Date":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "date",
				cellClassName: (params) => getCellClassName(params, columnKey)
			};

		case "errorFields":
			return {
				field: columnKey,
				flex: 1,
				editable: false,
				sortable: false,
				headerName: "Issues",
				cellClassName: (params) => getCellClassName(params, columnKey),
				valueGetter: (params: GridValueGetterParams<CombinedRowType, CombinedRowErrorFieldType>) => {
					const errorsArray = Object.values(params.value ?? {});

					if (errorsArray.length <= 0) return NO_ROW_ISSUES_MESSAGE;
					if (errorsArray.length === 1) return errorsArray[0];

					const joinedArray = errorsArray.slice(0, -1).join(", ");

					return `${joinedArray} and ${errorsArray[errorsArray.length - 1]}`;
				},
				renderCell: (params: GridRenderCellParams<CombinedRowType, string>) => {
					const errorMessage = params.value ?? "";

					return (
						<Tooltip
							title={errorMessage}
							enterDelay={2000}
							placement="bottom"
							slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -14] } }] } }}
						>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between"
								}}
							>
								<Typography variant="body2" sx={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
									{errorMessage}
								</Typography>

								{errorMessage !== NO_ROW_ISSUES_MESSAGE ? (
									<IconButton color="error" size="small" onClick={() => openErrorDialogCallback(errorMessage)}>
										<LaunchIcon fontSize="inherit" />
									</IconButton>
								) : null}
							</Box>
						</Tooltip>
					);
				}
			};

		case "Comment":
			return {
				field: columnKey,
				flex: 1,
				editable: true,
				sortable: false,
				type: "string",
				cellClassName: (params) => getCellClassName(params, columnKey)
			};

		default:
			return null;
	}
}

function validateRowDetails(
	rowDetails: CombinedRowType,
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean,
	isNumberPlateDuplicate?: boolean
): CombinedRowErrorFieldType {
	if (rowDetails.is_deleted && rowDetails.errorFields) {
		return rowDetails.errorFields;
	}

	const rowErrorFields: CombinedRowErrorFieldType = {};

	if ("Vehicle Owner Name" in rowDetails) {
		const errors = [];

		if (validations.name.strip) {
			rowDetails["Vehicle Owner Name"] = rowDetails["Vehicle Owner Name"].trim();
		}

		if (validations.name.regex && !validations.name.regex.test(rowDetails["Vehicle Owner Name"])) {
			if (rowDetails["Vehicle Owner Name"] || (!rowDetails["Vehicle Owner Name"] && validations.name.allow_null)) {
				errors.push(INVALID_OWNER_NAME_ERROR_MESSAGE);
			}
		}

		if (
			typeof validations.name.char_size === "number" &&
			rowDetails["Vehicle Owner Name"].trim().length > validations.name.char_size
		) {
			errors.push(getCharLimitErrorMessage(validations.name.char_size, "owner_name"));
		}

		if (errors.length > 0) {
			rowErrorFields["Vehicle Owner Name"] = errors;
		}
	}

	if ("Flat Number" in rowDetails) {
		if (customFlatSelectionEnabled) {
			const errors = [];

			if (!rowDetails["Flat Number"] && !validations.flat_number.allow_null) {
				errors.push(EMPTY_FLAT_NUMBER_ERROR_MESSAGE);
			}

			if (
				typeof validations.flat_number.char_size === "number" &&
				rowDetails["Flat Number"].length > validations.flat_number.char_size
			) {
				errors.push(getCharLimitErrorMessage(validations.flat_number.char_size, "flat_number"));
			}

			if (errors.length > 0) {
				rowErrorFields["Flat Number"] = errors;
			}
		} else {
			if (!listsData.flatsList.includes(rowDetails["Flat Number"])) {
				if (rowDetails["Flat Number"] || (!rowDetails["Flat Number"] && !validations.flat_number.allow_null)) {
					rowErrorFields["Flat Number"] = [INVALID_FLAT_NUMBER_ERROR_MESSAGE];
				}
			}
		}
	}

	if ("Number Plate" in rowDetails) {
		const errors = [];

		if (validations.number_plate.strip) {
			rowDetails["Number Plate"] = rowDetails["Number Plate"].trim();
		}

		if (validations.number_plate.regex && !validations.number_plate.regex.test(rowDetails["Number Plate"])) {
			if (rowDetails["Number Plate"] || (!rowDetails["Number Plate"] && !validations.number_plate.allow_null)) {
				errors.push(INVALID_VEHICLE_NUMBER_ERROR_MESSAGE);
			}
		}

		if (isNumberPlateDuplicate) {
			errors.push(DUPLICATE_VEHICLE_NUMBER_ERROR_MESSAGE);
		}

		if (errors.length > 0) {
			rowErrorFields["Number Plate"] = errors;
		}
	}

	if ("Contact Number" in rowDetails) {
		if (validations.phone_number.strip) {
			rowDetails["Contact Number"] = rowDetails["Contact Number"].trim();
		}

		if (validations.phone_number.regex && !validations.phone_number.regex.test(rowDetails["Contact Number"])) {
			if (rowDetails["Contact Number"] || (!rowDetails["Contact Number"] && !validations.phone_number.allow_null)) {
				rowErrorFields["Contact Number"] = [INVALID_CONTACT_NUMBER_ERROR_MESSAGE];
			}
		}
	}

	if ("Vehicle Type" in rowDetails) {
		if (rowDetails["Vehicle Type"]) {
			if (!listsData.vehicleTypesList.some((item) => item.name === rowDetails["Vehicle Type"])) {
				rowErrorFields["Vehicle Type"] = [INVALID_VEHICLE_TYPE_ERROR_MESSAGE];
			}
		} else {
			if (!validations.vehicle_type.allow_null) {
				rowErrorFields["Vehicle Type"] = [INVALID_VEHICLE_TYPE_ERROR_MESSAGE];
			}
		}
	}

	if ("Owner Type" in rowDetails) {
		const ownerTypeDetails = listsData.ownerTypesList.find(
			(ownerTypeItem) => ownerTypeItem.type_of.toLowerCase() === rowDetails["Owner Type"].toLowerCase()
		);

		if (!ownerTypeDetails) {
			rowErrorFields["Owner Type"] = [INVALID_OWNER_TYPE_ERROR_MESSAGE];
		}

		if (ownerTypeDetails && ownerTypeDetails.has_expiry) {
			if ("Validity Start Date" in rowDetails && "Validity End Date" in rowDetails) {
				if (
					!rowDetails["Validity Start Date"] ||
					(rowDetails["Validity Start Date"] && !moment(rowDetails["Validity Start Date"]).isValid())
				) {
					rowErrorFields["Validity Start Date"] = [INVALID_START_DATE_ERROR_MESSAGE];
				}

				if (rowDetails["Validity End Date"]) {
					if (
						moment(rowDetails["Validity End Date"]).isBefore(moment(rowDetails["Validity Start Date"])) ||
						!moment(rowDetails["Validity End Date"]).isValid()
					) {
						rowErrorFields["Validity End Date"] = [INVALID_END_DATE_ERROR_MESSAGE];
					}
				} else {
					rowErrorFields["Validity End Date"] = [INVALID_END_DATE_ERROR_MESSAGE];
				}
			}
		}
	}

	return rowErrorFields;
}

function getRowsCols<T extends "whitelist" | "blacklist">(
	data: DataSet,
	sheetName: string,
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean,
	openErrorDialogCallback: (errorMessage: string) => void,
	openColumnFilterCallback: (anchorElement: HTMLDivElement, columnName: string) => void
): RowCol[T] {
	const returnData: RowCol[T] = {
		rows: [],
		skippedRows: [],
		columns: []
	};

	const jsonData = utils.sheet_to_json<CombinedRowType>(data[sheetName], {
		blankrows: true,
		skipHidden: false,
		raw: false,
		defval: ""
	});

	if (jsonData.length > 0) {
		for (const key of Object.keys(jsonData[0])) {
			const columnDetails = getColumnDef(
				key as keyof CombinedRowType,
				listsData,
				validations,
				customFlatSelectionEnabled,
				openErrorDialogCallback,
				openColumnFilterCallback
			);
			if (columnDetails) {
				returnData.columns.push(columnDetails);
			}
		}
	}

	const errorColumnDetails = getColumnDef(
		"errorFields",
		listsData,
		validations,
		customFlatSelectionEnabled,
		openErrorDialogCallback,
		openColumnFilterCallback
	);

	if (errorColumnDetails) returnData.columns.push(errorColumnDetails);

	// returnData.columns.push({
	// 	field: "actions",
	// 	width: 50,
	// 	headerName: "",
	// 	renderCell: (params: GridRenderCellParams<CombinedRowType>) => (
	// 		<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
	// 			<IconButton size="small" color="error" onClick={() => deleteButtonCallback(params.row.id)}>
	// 				<DeleteIcon fontSize="small" />
	// 			</IconButton>
	// 		</Box>
	// 	)
	// });

	for (const [index, rowDetails] of Object.entries(jsonData)) {
		const numberPlate = rowDetails["Number Plate"].replaceAll(/\s/g, "").toUpperCase();

		const isNumberPlateDuplicate = Object.values(jsonData).some((checkRowItem) => {
			return (
				numberPlate &&
				typeof checkRowItem.id === "number" &&
				checkRowItem.id !== Number(index) &&
				checkRowItem["Number Plate"] === numberPlate &&
				!checkRowItem.is_deleted
			);
		});

		const updatedRowDetails: CombinedRowType = {
			...rowDetails,
			id: Number(index),
			sr_no: Number(index) + 1,
			"Number Plate": numberPlate
		};

		if ("Validity Start Date" in rowDetails) {
			updatedRowDetails["Validity Start Date"] =
				rowDetails["Validity Start Date"] && String(rowDetails["Validity Start Date"]) !== EXCEL_CELL_EMPTY_PLACEHOLDER
					? moment(rowDetails["Validity Start Date"], VALIDITY_DATE_COMPATIBLE_FORMATS).toDate()
					: null;
		}

		if ("Validity End Date" in rowDetails) {
			updatedRowDetails["Validity End Date"] =
				rowDetails["Validity End Date"] && String(rowDetails["Validity End Date"]) !== EXCEL_CELL_EMPTY_PLACEHOLDER
					? moment(rowDetails["Validity End Date"], VALIDITY_DATE_COMPATIBLE_FORMATS).toDate()
					: null;
		}

		if (rowDetails["Owner Type"]) {
			updatedRowDetails["Owner Type"] = convertToTitleCase(rowDetails["Owner Type"]);
		}

		updatedRowDetails.errorFields = validateRowDetails(
			updatedRowDetails,
			listsData,
			validations,
			customFlatSelectionEnabled,
			isNumberPlateDuplicate
		);

		if (updatedRowDetails["Contact Number"]) {
			updatedRowDetails["Contact Number"] = updatedRowDetails["Contact Number"].trim();
		}

		if (updatedRowDetails["Comment"]) {
			updatedRowDetails["Comment"] = updatedRowDetails["Comment"].trim();
		}

		if (updatedRowDetails["Flat Number"]) {
			updatedRowDetails["Flat Number"] = updatedRowDetails["Flat Number"].trim();
		}

		if (updatedRowDetails["Vehicle Owner Name"]) {
			updatedRowDetails["Vehicle Owner Name"] = convertToTitleCase(updatedRowDetails["Vehicle Owner Name"].trim());
		}

		const rows = returnData.rows as CombinedRowType[];

		const duplicateRow = rows.find((item) => {
			const { id: _itemId, sr_no: _itemSrNo, ...itemRest } = item;
			const { id: _rowId, sr_no: rowSrNo, ...rowRest } = updatedRowDetails;

			return JSON.stringify(itemRest) === JSON.stringify(rowRest);
		});

		if (duplicateRow) {
			returnData.skippedRows.push(updatedRowDetails);
		} else {
			returnData.rows.push(updatedRowDetails);
		}
	}

	return returnData;
}

function processFileData<T extends "whitelist" | "blacklist">(
	fileArrayBuffer: ArrayBuffer,
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean,
	openErrorDialogCallback: (errorMessage: string) => void,
	openColumnFilterCallback: (anchorElement: HTMLDivElement, columnName: string) => void
): RowCol[T] {
	const workbook = read(fileArrayBuffer);
	const sheetName = workbook.SheetNames[0];

	return getRowsCols<T>(
		workbook.Sheets,
		sheetName,
		listsData,
		validations,
		customFlatSelectionEnabled,
		openErrorDialogCallback,
		openColumnFilterCallback
	);
}

export const handleProcessFileData: <T extends "whitelist" | "blacklist">(
	fileArrayBuffer: ArrayBuffer,
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean,
	openErrorDialogCallback: (errorMessage: string) => void,
	openColumnFilterCallback: (anchorElement: HTMLDivElement, columnName: string) => void
) => RowCol[T] = processFileData;

export const handleProcessEditedRowDetails = (
	newRow: CombinedRowType,
	updatedRowsData: CombinedRowType[],
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean
): CombinedRowType[] => {
	const selectedRowIndex = updatedRowsData.findIndex((item) => item.id === newRow.id);

	if (selectedRowIndex >= 0) {
		newRow["Number Plate"] = newRow["Number Plate"].trim().toUpperCase();
		updatedRowsData[selectedRowIndex] = newRow;
	}

	return updatedRowsData.map((rowItem) => {
		const isNumberPlateDuplicate = updatedRowsData.some(
			(checkRowItem) =>
				rowItem["Number Plate"] &&
				checkRowItem.id !== rowItem.id &&
				checkRowItem["Number Plate"] === rowItem["Number Plate"] &&
				!checkRowItem.is_deleted
		);

		rowItem.errorFields = validateRowDetails(
			rowItem,
			listsData,
			validations,
			customFlatSelectionEnabled,
			isNumberPlateDuplicate
		);
		return rowItem;
	});
};

export const handleRevalidateRows = (
	rowsData: CombinedRowType[],
	columnsData: GridColDef[],
	listsData: IListsData,
	validations: IExcelValidationDetails,
	customFlatSelectionEnabled: boolean,
	openErrorDialogCallback: (errorMessage: string) => void,
	openColumnFilterCallback: (anchorElement: HTMLDivElement, columnName: string) => void
) => {
	const updatedRowsData = rowsData.map((rowItem) => {
		const isNumberPlateDuplicate = rowsData.some(
			(checkRowItem) =>
				rowItem["Number Plate"] &&
				rowItem.id !== checkRowItem.id &&
				rowItem["Number Plate"] === checkRowItem["Number Plate"] &&
				!checkRowItem.is_deleted
		);

		rowItem.errorFields = validateRowDetails(
			rowItem,
			listsData,
			validations,
			customFlatSelectionEnabled,
			isNumberPlateDuplicate
		);
		return rowItem;
	});

	const updatedColumnsData = [...columnsData];

	const flatNumberIndex = updatedColumnsData.findIndex((columnItem) => columnItem.field === "Flat Number");

	if (flatNumberIndex >= 0) {
		const flatNumberColumnDef = getColumnDef(
			"Flat Number",
			listsData,
			validations,
			customFlatSelectionEnabled,
			openErrorDialogCallback,
			openColumnFilterCallback
		);

		if (flatNumberColumnDef) updatedColumnsData[flatNumberIndex] = flatNumberColumnDef;
	}

	return { rows: updatedRowsData, columns: updatedColumnsData };
};
