import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridPagination, GridPaginationModel, GridRenderCellParams } from "@mui/x-data-grid";
import React, { FC, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./deviceCameraSettings.css";
import { PAGINATION_CONSTANTS } from "../../../utils/constants";
import { useGetDevicesListQuery } from "../../../redux/reducers/settings.reducer";
import TablePagination from "../../../components/TablePagination";
import { IDeviceDetails, IGetDevicesListRequestData } from "../../../types";
import ConnectedCamerasDialog from "./ConnectedCamerasDialog";

export interface IDeviceRowDetails {
	id: number;
	status: "Online" | "Offline";
	name: string;
	serial_number: string;
	system_version: string;
	local_ip: string;
	global_ip: string;
}

// interface IOnlineDeviceRowDetails extends IBaseDeviceRowDetails {
// 	status: "online";
// }

// interface IOfflineDeviceRowDetails extends IBaseDeviceRowDetails {
// 	status: "offline";
// 	offline_hours: {
// 		from: string;
// 		to: string;
// 		duration: string;
// 	};
// }

// type IDeviceRowDetails = IOnlineDeviceRowDetails | IOfflineDeviceRowDetails;

// const DeviceStatusTooltip = styled(({ className, ...props }: TooltipProps) => (
// 	<Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
// 	[`& .${tooltipClasses.tooltip}`]: {
// 		backgroundColor: "#D7D1FF",
// 		color: "var(--color-text-primary)",
// 		fontSize: 12,
// 		boxShadow: theme.shadows[2],
// 		fontWeight: 400,
// 		textAlign: "center",
// 		maxWidth: 200
// 	},
// 	[`& .${tooltipClasses.arrow}`]: {
// 		color: "#D7D1FF"
// 	}
// }));

const DevicesListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const DeviceCameraSettings: FC = () => {
	const [filters, setFilters] = useState<IGetDevicesListRequestData>({ page: 1 });
	const [selectedDeviceDetails, setSelectedCameraDetails] = useState<IDeviceRowDetails | null>(null);

	// APIS
	const {
		data: getDevicesListResponse,
		isLoading: getDevicesListLoading,
		isFetching: getDevicesListFetching
	} = useGetDevicesListQuery(filters);

	function handlePageChange(event: GridPaginationModel) {
		setFilters((currentFiltersState) => ({
			...currentFiltersState,
			page: event.page + 1
		}));
	}

	function handleOpenDeviceConnectedCamerasDialog(deviceDetails: IDeviceRowDetails) {
		setSelectedCameraDetails(deviceDetails);
	}

	function handleCloseDeviceConnectedCamerasDialog() {
		setSelectedCameraDetails(null);
	}

	// RENDERER FUNCTIONS
	function renderStatusColumnCell(params: GridRenderCellParams<IDeviceRowDetails, "Online" | "Offline">) {
		return (
			<Box className={`devices-table-status-column-cell ${params.value}`}>
				<FiberManualRecordIcon color="inherit" fontSize="inherit" />

				<Typography variant="body2" textTransform="capitalize" color="var(--color-text-primary)">
					{params.value}
				</Typography>

				{/* {params.row.status === "offline" && params.row.offline_hours ? (
					<DeviceStatusTooltip
						arrow
						placement="top"
						title={`The device is offline for ${params.row.offline_hours.duration} from ${moment(
							params.row.offline_hours.from,
							"DD-MM-YYYY_HH:mm"
						).format("hh:mm A")} to ${moment(params.row.offline_hours.to, "DD-MM-YYYY_HH:mm").format("hh:mm A")}`}
						slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -8] } }] } }}
					>
						<InfoOutlinedIcon fontSize="inherit" sx={{ color: "var(--color-info-dark)" }} />
					</DeviceStatusTooltip>
				) : null} */}
			</Box>
		);
	}

	function renderGlobalIPColumnCell(params: GridRenderCellParams<IDeviceRowDetails, string>) {
		return (
			<Tooltip
				title={params.value}
				enterDelay={2000}
				placement="bottom"
				slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -14] } }] } }}
			>
				<Box className="devices-table-global-ip-column-cell">
					<Typography variant="body2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
						{params.value}
					</Typography>

					<IconButton size="small" onClick={() => handleOpenDeviceConnectedCamerasDialog(params.row)}>
						<OpenInNewIcon fontSize="inherit" sx={{ color: "var(--color-info-dark)" }} />
					</IconButton>
				</Box>
			</Tooltip>
		);
	}

	const columnsData: GridColDef[] = [
		{
			field: "status",
			headerName: "Status",
			minWidth: 120,
			flex: 1,
			sortable: false,
			renderCell: renderStatusColumnCell
		},
		{ field: "name", headerName: "Device Name", minWidth: 200, flex: 2, sortable: false },
		{ field: "serial_number", headerName: "Serial Number", minWidth: 300, flex: 1, sortable: false },
		{ field: "system_version", headerName: "System Version", minWidth: 150, flex: 1, sortable: false },
		{ field: "local_ip", headerName: "Local IP", minWidth: 150, flex: 1, sortable: false },
		{
			field: "global_ip",
			headerName: "Global IP",
			minWidth: 165,
			flex: 1,
			sortable: false,
			renderCell: renderGlobalIPColumnCell
		}
	];

	function getDevicesRowsDetails(devicesList: IDeviceDetails[]): IDeviceRowDetails[] {
		return devicesList.map((deviceItem) => ({
			status: deviceItem.device_status ?? "Offline",
			id: deviceItem.id,
			name: deviceItem.device_name,
			serial_number: deviceItem.device_uuid,
			system_version: deviceItem.release_version,
			local_ip: deviceItem.local_ipv4_address,
			global_ip: deviceItem.global_ipv4_address
		}));
	}

	return (
		<Box className="device-camera-settings-screen-wrapper">
			<Box className="device-camera-settings-screen-header">
				<Typography variant="h6">Devices Connected</Typography>
			</Box>

			<Box className="devices-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={getDevicesRowsDetails(getDevicesListResponse?.results ?? [])}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					pageSizeOptions={[PAGINATION_CONSTANTS.ROWS_PER_PAGE]}
					onPaginationModelChange={handlePageChange}
					paginationModel={{ page: filters.page ? filters.page - 1 : 0, pageSize: PAGINATION_CONSTANTS.ROWS_PER_PAGE }}
					hideFooter={(getDevicesListResponse?.count ?? 0) <= PAGINATION_CONSTANTS.ROWS_PER_PAGE}
					loading={getDevicesListLoading || getDevicesListFetching}
					classes={{
						columnSeparator: "devices-table-column-separator",
						columnHeader: "devices-table-column-header"
					}}
					slots={{ pagination: DevicesListPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
				/>
			</Box>

			<ConnectedCamerasDialog
				open={!!selectedDeviceDetails}
				deviceDetails={selectedDeviceDetails}
				onClose={handleCloseDeviceConnectedCamerasDialog}
			/>
		</Box>
	);
};

export default DeviceCameraSettings;
