import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { sortBy } from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./uptime.css";
import DeviceSelection from "../../components/DeviceSelection";
import TimeRangeSelection from "../../components/TimeRangeSelection";
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams } from "@mui/x-data-grid";
import { PAGINATION_CONSTANTS, API_TIMESTAMP_FORMAT } from "../../utils/constants";
import DowntimeEventInfoDialog from "./DowntimeEventInfoDialog";
import {
	IGetUptimeMetricsRequestData,
	IUptimeCardDetails,
	IUptimeHistoryDetails,
	IUptimeRowDetails,
	UptimeCardValueType
} from "../../types";
import { useGetUptimeMetricsQuery } from "../../redux/reducers/uptime.reducer";
import { getFormattedDuration } from "../../utils/commonUtils";
import downloadServices from "../../redux/services/download.services";
import TablePagination from "../../components/TablePagination";
import { useAppSelector } from "../../redux";
import { useGetAllDevicesListQuery } from "../../redux/reducers/settings.reducer";

const UPTIME_CARDS_LIST: IUptimeCardDetails[] = [
	{
		type: "uptime",
		icon: <SystemUpdateAltIcon color="inherit" className="rotate" />,
		name: "Total Uptime",
		value: "percent_uptime"
	},
	{
		type: "uptime",
		icon: <TimelapseIcon color="inherit" />,
		name: "Total Uptime Duration",
		value: "uptime_seconds"
	},
	{
		type: "downtime",
		icon: <SystemUpdateAltIcon color="inherit" />,
		name: "Total Downtime",
		value: "percent_downtime"
	},
	{
		type: "downtime",
		icon: <TimelapseIcon color="inherit" />,
		name: "Total Downtime Duration",
		value: "downtime_seconds"
	}
];

const UptimeListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const Uptime: FC = () => {
	// STORE
	const filtersState = useAppSelector((state) => state.filters);
	const { timeFilter } = filtersState;

	// STATES
	const [selectedUptimeEvent, setSelectedUptimeEvent] = useState<IUptimeRowDetails | null>(null);
	const [showDownloadButtonLoader, setShowDownloadButtonLoader] = useState<boolean>(false);
	const [filters, setFilters] = useState<IGetUptimeMetricsRequestData>({
		start_time: moment().startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER),
		end_time: moment().endOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER)
	});

	// APIS
	// GET ALL DEVICES LIST
	const { data: getDevicesListResponse } = useGetAllDevicesListQuery();

	const uptimeFilters = useMemo<IGetUptimeMetricsRequestData>(() => {
		if (!filters.device_id && getDevicesListResponse && getDevicesListResponse.length > 0) {
			return {
				...filters,
				device_id: getDevicesListResponse[0].id
			};
		}

		return filters;
	}, [filters, getDevicesListResponse]);

	const { data: uptimeMetricsResponse } = useGetUptimeMetricsQuery(uptimeFilters, {
		skip: typeof uptimeFilters.device_id !== "number"
	});

	const rowsData = useMemo<IUptimeRowDetails[]>(() => {
		if (uptimeMetricsResponse && uptimeMetricsResponse.data && typeof uptimeFilters.device_id === "number") {
			const sortedUptimeMetrics = sortBy(uptimeMetricsResponse.data, "date");

			return sortedUptimeMetrics.map((uptimeMetricsItem, index) => {
				const sortedNestedData = sortBy(uptimeMetricsItem.nested_data, "start_time");

				const uptimeHistory: IUptimeHistoryDetails[] = sortedNestedData.map((nestedDataItem) => ({
					status: nestedDataItem.is_online_data,
					from: nestedDataItem.start_time,
					to: nestedDataItem.end_time,
					duration: nestedDataItem.duration
				}));

				return {
					id: index,
					sr_no: index + 1,
					date: uptimeMetricsItem.date,
					uptime: getFormattedDuration(Number(uptimeMetricsItem.uptime)),
					downtime: getFormattedDuration(Number(uptimeMetricsItem.downtime)),
					history: uptimeHistory
				};
			});
		}

		return [];
	}, [uptimeFilters.device_id, uptimeMetricsResponse]);

	function handleOpenDowntimeEventInfoDialog(eventInfo: IUptimeRowDetails) {
		setSelectedUptimeEvent(eventInfo);
	}

	function handleCloseDowntimeEventInfoDialog() {
		setSelectedUptimeEvent(null);
	}

	function handleChangeDateValues(from: string, to: string) {
		setFilters((currentFiltersState) => ({
			...currentFiltersState,
			start_time: from,
			end_time: to
		}));
	}

	function handleChangeDevice(deviceIds: number[]) {
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (deviceIds.length >= 0) updatedFilters.device_id = deviceIds[0];
			else delete updatedFilters.device_id;

			return updatedFilters;
		});
	}

	function getUptimeMetricsValue(key: UptimeCardValueType): string {
		if (!uptimeMetricsResponse || typeof uptimeFilters.device_id !== "number") return "--";

		switch (key) {
			case "percent_uptime":
				return `${uptimeMetricsResponse.percent_uptime.toFixed(0)}%`;

			case "uptime_seconds":
				return getFormattedDuration(uptimeMetricsResponse.uptime_seconds);

			case "percent_downtime":
				return `${uptimeMetricsResponse.percent_downtime.toFixed(0)}%`;

			case "downtime_seconds":
				return getFormattedDuration(uptimeMetricsResponse.downtime_seconds);

			default:
				return "--";
		}
	}

	function handleDownloadButtonClick() {
		setShowDownloadButtonLoader(true);

		downloadServices
			.handleDownloadUptimeLogs(uptimeFilters)
			.catch((error) => console.error("DOWNLOAD UPTIME LOGS FAILED ::", error))
			.finally(() => setShowDownloadButtonLoader(false));
	}

	const columnsData: GridColDef[] = [
		{ field: "sr_no", headerName: "Sr. No.", width: 100, sortable: false },
		{
			field: "date",
			headerName: "Date",
			minWidth: 300,
			flex: 2,
			sortable: false,
			valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY")
		},
		{ field: "uptime", headerName: "Uptime", minWidth: 200, flex: 1, sortable: false },
		{ field: "downtime", headerName: "Downtime", minWidth: 200, flex: 1, sortable: false },
		{
			field: "info",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IUptimeRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton size="small" onClick={() => handleOpenDowntimeEventInfoDialog(params.row)}>
						<InfoOutlinedIcon fontSize="inherit" sx={{ color: "var(--color-info-dark)" }} />
					</IconButton>
				</Box>
			)
		}
	];

	useEffect(() => {
		if (timeFilter && timeFilter.start_time && timeFilter.end_time) {
			handleChangeDateValues(timeFilter.start_time, timeFilter.end_time);
		}
	}, [timeFilter]);

	return (
		<Box className="uptime-screen-wrapper">
			<Box className="uptime-screen-header-wrapper">
				<DeviceSelection disableAllSelected onChange={handleChangeDevice} />

				<Box className="uptime-table-actions-wrapper">
					<TimeRangeSelection customOnly />

					<Box className="uptime-table-download-button" onClick={handleDownloadButtonClick}>
						{showDownloadButtonLoader ? (
							<CircularProgress color="inherit" size={20} />
						) : (
							<DownloadIcon color="inherit" fontSize="small" />
						)}
					</Box>
				</Box>
			</Box>

			<Box className="uptime-screen-header-cards-wrapper">
				<Grid container spacing={2}>
					{UPTIME_CARDS_LIST.map((uptimeCardItem, index) => (
						<Grid item xs={12} md={3} key={index}>
							<Box className="uptime-screen-header-card">
								<Box
									className={`uptime-screen-header-card-icon ${
										uptimeCardItem.type === "uptime" ? "success" : uptimeCardItem.type === "downtime" ? "error" : ""
									}`}
								>
									{uptimeCardItem.icon}
								</Box>

								<Box className="uptime-screen-header-card-text">
									<Typography
										variant="body2"
										fontWeight={400}
										color="#00000080"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										overflow="hidden"
									>
										{uptimeCardItem.name}
									</Typography>

									<Typography fontWeight={500}>{getUptimeMetricsValue(uptimeCardItem.value)}</Typography>
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>

			<Box className="uptime-screen-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={rowsData}
					disableColumnMenu
					disableRowSelectionOnClick
					density="compact"
					rowSpacingType="border"
					columnHeaderHeight={80}
					pageSizeOptions={[PAGINATION_CONSTANTS.ROWS_PER_PAGE]}
					slots={{ pagination: UptimeListPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					hideFooter={(uptimeMetricsResponse?.data?.length ?? 0) <= PAGINATION_CONSTANTS.ROWS_PER_PAGE}
					classes={{
						root: "uptime-table-root",
						columnHeader: "uptime-table-column-header",
						columnSeparator: "uptime-table-column-separator"
					}}
				/>
			</Box>

			<DowntimeEventInfoDialog
				open={!!selectedUptimeEvent}
				eventDetails={selectedUptimeEvent}
				onClose={handleCloseDowntimeEventInfoDialog}
			/>
		</Box>
	);
};

export default Uptime;
