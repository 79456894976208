import {
	AppBar,
	Avatar,
	Box,
	Button,
	CircularProgress,
	Collapse,
	CssBaseline,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography
} from "@mui/material";
import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LogoutIcon from "@mui/icons-material/Logout";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import DvrIcon from "@mui/icons-material/Dvr";
import StorageIcon from "@mui/icons-material/Storage";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import BlockIcon from "@mui/icons-material/Block";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";

import "./pageLayout.css";
import { ReactComponent as GGConnectLogo } from "../../assets/logos/gg-connect-logo-new.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { clearAuthState, clearUnauthState, logoutUser } from "../../redux/reducers/auth.reducer";
import authServices from "../../redux/services/auth.services";
import { useGetSocietyConfigQuery, useGetSystemIntegratorDetailsQuery } from "../../redux/reducers/settings.reducer";
import { ISocietyConfigDetails, UserLoginType } from "../../types";
import { CONFIG_MODE_E, SI_ACCESSIBLE_PAGES, USER_ROLES_E } from "../../utils/constants";
import { convertKebabToTitleCase } from "../../utils/commonUtils";
import SecureLoginDialog from "../../components/SecureLoginDialog";
import { closeSecureLoginDialog } from "../../redux/reducers/secureLoginDialog.reducer";
import EditProfileDialog from "./EditProfileDialog";

interface ISidebarItem {
	text: string;
	icon: React.ReactNode;
	path: string;
	children?: Omit<ISidebarItem, "children">[];
}

const drawerWidth = 280;

const PAGE_TITLE = "GateGuard Connect";

const sidebarItemsList: ISidebarItem[] = [
	{
		text: "Dashboard",
		icon: <DashboardIcon color="inherit" />,
		path: "/dashboard"
	},
	{
		text: "Uptime",
		icon: <StackedBarChartIcon color="inherit" />,
		path: "/uptime"
	},
	{
		text: "Event Logs",
		icon: <DvrIcon color="inherit" />,
		path: "/event-logs"
	},
	{
		text: "Data Management",
		icon: <StorageIcon color="inherit" />,
		path: "/data-management",
		children: [
			{
				text: "Authorized List",
				icon: <PlaylistAddCheckIcon color="inherit" />,
				path: "/authorized-list"
			},
			{
				text: "Blacklist",
				icon: <BlockIcon color="inherit" />,
				path: "/blacklist"
			}
		]
	},
	{
		text: "Configuration",
		icon: <SettingsIcon color="inherit" />,
		path: "/configuration"
	}
];

const PageLayout: FC = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const authState = useAppSelector((state) => state.auth);
	const secureLoginDialogState = useAppSelector((state) => state.secureLoginDialog);

	const currentLoginType = useRef<UserLoginType>(authState.user.login_type);
	const profileMenuAnchorElement = useRef<HTMLDivElement>(null);

	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);
	const [expandedSidebarItem, setExpandedSidebarItem] = useState<string>("");
	const [showSupportContactDetailsDialog, setShowSupportContactDetailsDialog] = useState<boolean>(false);
	const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
	const [editProfileDialogOpen, setEditProfileDialogOpen] = useState<boolean>(false);

	const siderbarRenderItems = useMemo<ISidebarItem[]>(() => {
		if (authState.user.role === USER_ROLES_E.SYSTEM_INTEGRATOR) {
			return sidebarItemsList.filter((item) => SI_ACCESSIBLE_PAGES.includes(item.path));
		}

		if (authState.user.mode === CONFIG_MODE_E.MY_GATE) {
			return sidebarItemsList.filter((item) => item.path !== "/data-management");
		}

		return sidebarItemsList;
	}, [authState.user.mode, authState.user.role]);

	// APIS
	const { data: getSocietyConfigResponse } = useGetSocietyConfigQuery(null, { refetchOnMountOrArgChange: true });
	const societyDetails: ISocietyConfigDetails | null =
		getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
			? getSocietyConfigResponse.results[0]
			: null;

	const { data: getSystemIntegratorDetailsResponse } = useGetSystemIntegratorDetailsQuery(null, {
		skip: authState.user.role === USER_ROLES_E.SYSTEM_INTEGRATOR
	});
	const systemIntegratorDetails = getSystemIntegratorDetailsResponse ? getSystemIntegratorDetailsResponse[0] : null;

	function handleOpenMobileDrawer() {
		setMobileOpen(true);
	}

	function handleCloseMobileDrawer() {
		setMobileOpen(false);
	}

	function handleExpandSidebarDropdown(path: string) {
		setExpandedSidebarItem(path);
		handleCloseMobileDrawer();
	}

	function handleNavigateToPath(path: string) {
		if (!location.pathname.startsWith(path)) {
			navigate(path);
			handleExpandSidebarDropdown(path);
		}
	}

	function handleNavigateToChildPath(path: string) {
		navigate(path);
		handleCloseMobileDrawer();
	}

	function handleOpenProfileMenu() {
		setProfileMenuOpen(true);
	}

	function handleCloseProfileMenu() {
		setProfileMenuOpen(false);
	}

	function handleLogout() {
		dispatch(logoutUser());
	}

	function handleLoginAgainClick() {
		dispatch(clearUnauthState());
	}

	function handleOpenContactSupportDialog() {
		setShowSupportContactDetailsDialog(true);
	}

	function handleCloseContactSupportDialog() {
		setShowSupportContactDetailsDialog(false);
	}

	function handleCloseSecureLoginDialog() {
		dispatch(closeSecureLoginDialog());
	}

	function handleOpenEditProfileDialog() {
		setEditProfileDialogOpen(true);
		handleCloseProfileMenu();
	}

	function handleCloseEditProfileDialog() {
		setEditProfileDialogOpen(false);
	}

	function checkLoginToken() {
		authServices
			.handleCheckLoginToken()
			.then(() => {
				setShowLoader(false);
			})
			.catch((error) => {
				if (Number(error) === 401) dispatch(clearAuthState());
			});
	}

	function getSocietyName(): string {
		if (authState.user.role === USER_ROLES_E.SYSTEM_INTEGRATOR) {
			if (!systemIntegratorDetails) return "";
			return systemIntegratorDetails.name;
		}

		if (!societyDetails || !societyDetails.name) return "";

		let societyName = societyDetails.name;

		// if (societyDetails.city) societyName = `${societyName}, ${societyDetails.city}`;

		return societyName;
	}

	useEffect(() => {
		if (!authState.user.token) {
			if (location.search) {
				navigate(`/login${location.search}&path=${location.pathname}`);
			} else {
				navigate(`/login?path=${location.pathname}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.user.token, navigate]);

	useEffect(() => {
		checkLoginToken();

		const activeScreen = sidebarItemsList.find((item) => location.pathname.startsWith(item.path));

		if (activeScreen) {
			if (authState.user.role === USER_ROLES_E.SYSTEM_INTEGRATOR && !SI_ACCESSIBLE_PAGES.includes(activeScreen.path)) {
				navigate("/uptime");
			} else if (activeScreen.children) {
				setExpandedSidebarItem(activeScreen.path);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const locationPath = location.pathname.split("/");

		const parentPath = locationPath[1];
		const childPath = locationPath[2];

		let title = PAGE_TITLE;

		const parentPathComponent = sidebarItemsList.find((item) => item.path === `/${parentPath}`);

		if (parentPathComponent) {
			title = `${parentPathComponent.text} | ${PAGE_TITLE}`;

			if (childPath) {
				title = `${convertKebabToTitleCase(childPath)} | ${PAGE_TITLE}`;

				if (parentPathComponent.children) {
					const childPathComponent = parentPathComponent.children.find((item) => item.path === `/${childPath}`);

					if (childPathComponent) {
						title = `${childPathComponent.text} | ${PAGE_TITLE}`;
					}
				}
			}
		}

		document.title = title;
	}, [location.pathname]);

	useEffect(() => {
		if (authState.user.token && authState.user.login_type !== currentLoginType.current) {
			currentLoginType.current = authState.user.login_type;
			setTimeout(() => {
				window.location.reload();
			}, 10);
		}
	}, [authState.user]);

	const drawer = (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
			<Box className="mobile-drawer-close-icon">
				<IconButton sx={{ color: "#616161" }} size="small" onClick={handleCloseMobileDrawer}>
					<CloseIcon color="inherit" />
				</IconButton>
			</Box>

			<Toolbar classes={{ root: "page-layout-toolbar" }}>
				<GGConnectLogo width="80%" />
			</Toolbar>

			<Divider sx={{ borderColor: "#C5C1EA33", width: "80%" }} />

			{authState.user.role !== USER_ROLES_E.SYSTEM_INTEGRATOR ? (
				<>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: "0.5rem",
							marginY: "0.75rem"
						}}
					>
						<LocationOnIcon color="primary" />

						<Typography
							variant="body2"
							fontWeight={600}
							color="var(--color-primary-main)"
							overflow="hidden"
							textOverflow="ellipsis"
						>
							{getSocietyName()}
						</Typography>
					</Box>

					<Divider sx={{ borderColor: "#C5C1EA33", width: "80%" }} />
				</>
			) : null}

			<List sx={{ paddingX: "0.75rem", width: "100%", flexGrow: 1 }}>
				{siderbarRenderItems.map((item, index) => (
					<Fragment key={index}>
						<ListItem
							key={index}
							disablePadding
							classes={{
								root: `sidebar-list-item ${location.pathname.startsWith(item.path) ? "active" : ""}`
							}}
							sx={{ color: location.pathname.startsWith(item.path) ? "white" : "#616161" }}
						>
							<ListItemButton onClick={() => handleNavigateToPath(item.path)}>
								<ListItemIcon sx={{ color: location.pathname.startsWith(item.path) ? "white" : "#616161" }}>
									{item.icon}
								</ListItemIcon>
								<ListItemText primary={item.text} />

								{item.children ? (
									expandedSidebarItem === item.path ? (
										<ArrowDropUpIcon sx={{ color: "white" }} />
									) : (
										<ArrowDropDownIcon sx={{ color: "#616161" }} />
									)
								) : null}
							</ListItemButton>
						</ListItem>

						{item.children ? (
							<Collapse in={expandedSidebarItem === item.path} timeout="auto" unmountOnExit>
								<List
									component="div"
									disablePadding
									sx={{
										paddingLeft: "2.5rem",
										paddingY: "0.25rem",
										backgroundColor: "#F8F8FF",
										paddingRight: "0.25rem",
										borderBottomLeftRadius: "7px",
										borderBottomRightRadius: "7px"
									}}
								>
									{item.children.map((childItem) => (
										<ListItem
											key={childItem.path}
											disablePadding
											classes={{
												root: `sidebar-list-item child ${
													location.pathname.startsWith(`${item.path}${childItem.path}`) ? "active" : ""
												}`
											}}
											sx={{
												color: location.pathname.startsWith(`${item.path}${childItem.path}`) ? "white" : "#616161"
											}}
										>
											<ListItemButton
												sx={{ pl: 2 }}
												onClick={() => handleNavigateToChildPath(`${item.path}${childItem.path}`)}
											>
												<ListItemIcon
													sx={{
														color: location.pathname.startsWith(`${item.path}${childItem.path}`) ? "white" : "#616161",
														minWidth: "40px"
													}}
												>
													{childItem.icon}
												</ListItemIcon>
												<ListItemText primary={childItem.text} primaryTypographyProps={{ fontSize: "inherit" }} />
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Collapse>
						) : null}
					</Fragment>
				))}
			</List>

			{authState.user.role !== USER_ROLES_E.SYSTEM_INTEGRATOR &&
			systemIntegratorDetails &&
			systemIntegratorDetails.logo ? (
				<Box
					sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "1rem", gap: "0.5rem" }}
				>
					<Typography variant="body2">Solution Partner</Typography>

					<img
						src={systemIntegratorDetails.logo}
						alt="Solution Partner"
						width="150px"
						style={{ maxHeight: "70px", objectFit: "contain" }}
					/>

					{systemIntegratorDetails.support_email || systemIntegratorDetails.support_phone_number ? (
						<Button variant="outlined" color="primary" onClick={handleOpenContactSupportDialog}>
							Contact Support
						</Button>
					) : null}
				</Box>
			) : null}
		</Box>
	);

	return (
		<>
			{showLoader ? (
				<Box sx={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			) : (
				<Box sx={{ display: "flex" }}>
					<CssBaseline />

					<Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}>
						<Drawer
							variant="temporary"
							open={mobileOpen}
							onClose={handleCloseMobileDrawer}
							ModalProps={{ keepMounted: true }}
							sx={{
								display: { xs: "block", lg: "none" },
								"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, border: "none" }
							}}
							PaperProps={{
								sx: { backgroundColor: "#FEFEFE" }
							}}
						>
							{drawer}
						</Drawer>

						<Drawer
							variant="permanent"
							sx={{
								display: { xs: "none", lg: "block" },
								"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, border: "none" }
							}}
							PaperProps={{
								sx: { backgroundColor: "#FEFEFE" }
							}}
							open
						>
							{drawer}
						</Drawer>
					</Box>

					<Box className="screen-content-wrapper" sx={{ width: { xs: "100%", lg: `calc(100vw - ${drawerWidth}px)` } }}>
						<AppBar
							sx={{
								backgroundColor: "#FEFEFE",
								padding: "1rem 0.5rem 1rem 0"
								// borderBottom: "1px solid #f2f1fb"
							}}
							position="relative"
							elevation={0}
						>
							<Box className="appbar-content-wrapper">
								<IconButton
									sx={{ color: "#616161", display: { xs: "block", lg: "none" } }}
									size="small"
									onClick={handleOpenMobileDrawer}
								>
									<GridMenuIcon color="inherit" />
								</IconButton>

								<Box className="header-actions-wrapper">
									<Box
										ref={profileMenuAnchorElement}
										component="div"
										className="header-avatar-wrapper"
										id="profile-button"
										aria-controls={"profile-menu"}
										aria-haspopup
										aria-expanded
										onClick={handleOpenProfileMenu}
									>
										<Typography variant="body2" fontWeight={500} textTransform="capitalize">
											{authState.user.full_name ?? ""}
										</Typography>

										<Avatar
											sx={{ width: "24px", height: "24px", fontSize: "inherit", bgcolor: "var(--color-primary-main)" }}
										>
											{(authState.user.full_name ?? "").slice(0, 1).toUpperCase()}
										</Avatar>
									</Box>

									<Menu
										id="profile-menu"
										anchorEl={profileMenuAnchorElement.current}
										open={profileMenuOpen}
										onClose={handleCloseProfileMenu}
										MenuListProps={{ "aria-labelledby": "profile-button" }}
										disableAutoFocusItem
									>
										<MenuItem disableRipple onClick={handleOpenEditProfileDialog}>
											<ListItemIcon>
												<PersonIcon fontSize="small" />
											</ListItemIcon>

											<ListItemText>
												<Typography variant="body2">Edit Profile</Typography>
											</ListItemText>
										</MenuItem>

										<MenuItem disableRipple onClick={handleLogout}>
											<ListItemIcon>
												<LogoutIcon fontSize="small" />
											</ListItemIcon>

											<ListItemText>
												<Typography variant="body2">Logout</Typography>
											</ListItemText>
										</MenuItem>
									</Menu>
								</Box>
							</Box>
						</AppBar>

						<Box component="main" className="screen-main-component">
							<Box className="screen-outlet-wrapper">
								<Outlet />
							</Box>
						</Box>
					</Box>

					<Dialog open={authState.unauthorizedError} maxWidth="xs" fullWidth>
						<DialogContent>
							<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
								<Typography variant="h6" textAlign={"center"}>
									Your login session has expired
								</Typography>

								<Button variant="contained" sx={{ marginTop: 2 }} onClick={handleLoginAgainClick}>
									Login Again
								</Button>
							</Box>
						</DialogContent>
					</Dialog>
				</Box>
			)}

			<Dialog open={showSupportContactDetailsDialog} onClose={handleCloseContactSupportDialog} maxWidth="sm" fullWidth>
				<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
					Support
					<Box className="close-dialog-icon-wrapper">
						<IconButton color="inherit" onClick={handleCloseContactSupportDialog}>
							<CloseIcon color="inherit" />
						</IconButton>
					</Box>
				</DialogTitle>

				<DialogContent classes={{ root: "dialog-content-root" }}>
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.25rem" }}>
						<Typography variant="h6" fontWeight={400}>
							{systemIntegratorDetails?.name ?? ""}
						</Typography>

						{systemIntegratorDetails ? (
							<img src={systemIntegratorDetails.logo} alt="Solution Partner" width="200px" />
						) : null}
					</Box>

					<Box
						sx={{
							display: "flex",
							width: "100%",
							alignItems: "center",
							justifyContent: "space-evenly",
							marginTop: "1rem"
						}}
					>
						{systemIntegratorDetails && systemIntegratorDetails.support_email ? (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "0.5rem",
									color: "var(--color-primary-main)"
								}}
							>
								<EmailIcon />

								<Link href={`mailto:${systemIntegratorDetails.support_email}`}>
									{systemIntegratorDetails.support_email}
								</Link>
							</Box>
						) : null}

						{systemIntegratorDetails && systemIntegratorDetails.support_phone_number ? (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "0.5rem",
									color: "var(--color-primary-main)"
								}}
							>
								<LocalPhoneIcon />

								<Link href={`tel:${systemIntegratorDetails.support_phone_number}`}>
									{systemIntegratorDetails.support_phone_number}
								</Link>
							</Box>
						) : null}
					</Box>
				</DialogContent>
			</Dialog>

			<SecureLoginDialog open={secureLoginDialogState.show} onClose={handleCloseSecureLoginDialog} />

			<EditProfileDialog open={editProfileDialogOpen} onClose={handleCloseEditProfileDialog} />
		</>
	);
};

export default PageLayout;
