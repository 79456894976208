import { getAPIHeaderOptions, transformErrorResponse } from "../../utils/apiUtils";
import {
	ExcelValidationKeyTypes,
	IEditProfileRequestData,
	IEditProfileResponseData,
	IEditSocietyRequestData,
	IExcelValidationDetails,
	IGetAllDevicesListResponseData,
	IGetAllSocietiesResponseData,
	IGetCameraFramesListRequestData,
	IGetCameraFramesResponse,
	IGetCameraNamesResponseData,
	IGetDevicesListRequestData,
	IGetDevicesListResponseData,
	IGetOwnerTypesListResponseData,
	IGetSocietyConfigResponseData,
	IGetSocietyReportsResponse,
	IGetUserActivityLogsRequestData,
	IGetUserActivityLogsResponseData,
	IGetVehiclesListRequestData,
	IGetVehiclesListResponse,
	IGetVehicleTypesResponse,
	IGetVisitorTypesResponseData,
	IPasswordResetConfirmRequestData,
	ISystemIntegratorDetails,
	IUpdateVehicleRequestData
} from "../../types";
import { queriesApi } from "./queries.reducer";

const settings = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET OWNER TYPES
		getOwnerTypes: builder.query<IGetOwnerTypesListResponseData, void>({
			query: () => ({
				url: "/analytics/ownertype",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET VISITOR TYPES
		getVisitorTypes: builder.query<IGetVisitorTypesResponseData, void>({
			query: () => ({
				url: "/analytics/visitortype",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET DEVICES LIST
		getDevicesList: builder.query<IGetDevicesListResponseData, IGetDevicesListRequestData>({
			query: () => ({
				url: "/devices/device",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET CAMERA NAMES
		getCameraNames: builder.query<IGetCameraNamesResponseData[], void>({
			query: () => ({
				url: "/analytics/camera_names",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET SOCIETY CONFIG
		getSocietyConfig: builder.query<IGetSocietyConfigResponseData, void | null>({
			query: () => ({
				url: "/analytics/society",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// EDIT SOCIETY CONFIG
		editSocietyConfig: builder.mutation<unknown, IEditSocietyRequestData>({
			query: (requestData) => ({
				url: `/analytics/society/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// GET FLATS LIST
		getFlatsList: builder.query<string[], void>({
			query: () => ({
				url: `/analytics/get_flat_numbers`,
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET CAMERA FRAMES LIST
		getCameraFramesList: builder.query<IGetCameraFramesResponse[], IGetCameraFramesListRequestData>({
			query: (requestData) => ({
				url: "/devices/camera_frames",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// GET SYSTEM INTEGRATOR DETAILS
		getSystemIntegratorDetails: builder.query<ISystemIntegratorDetails[], void | null>({
			query: () => ({
				url: "/system-integrators/system-integrator-data",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET EXCEL VALIDATIONS
		getExcelValidations: builder.query<IExcelValidationDetails, void>({
			query: () => ({
				url: "/analytics/excel-validations",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			}),
			transformResponse: (response: { data: IExcelValidationDetails }) => {
				for (const key of Object.keys(response.data)) {
					const objectKey = key as ExcelValidationKeyTypes;

					if (response.data[objectKey].regex) {
						const regexString = String(response.data[objectKey].regex);
						const regexParts = regexString.match(/^\/(.*?)\/([gimsuy]*)$/);

						if (regexParts) {
							const regexPattern = regexParts[1];
							const regexFlags = regexParts[2];
							const regex = new RegExp(regexPattern, regexFlags);

							response.data[objectKey].regex = regex;
						} else {
							response.data[objectKey].regex = new RegExp(regexString);
						}
					}
				}

				return response.data;
			}
		}),

		// GET VEHICLES LIST
		getVehiclesList: builder.query<IGetVehiclesListResponse, IGetVehiclesListRequestData>({
			query: (requestData) => ({
				url: "/analytics/vehicle",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// UPDATE VEHICLE
		updateVehicle: builder.mutation<unknown, IUpdateVehicleRequestData>({
			query: (requestData) => ({
				url: `/analytics/vehicle/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// GET USER ACTIVITY LOGS
		getUserActivityLogs: builder.query<IGetUserActivityLogsResponseData, IGetUserActivityLogsRequestData>({
			query: (requestData) => ({
				url: "/analytics/user-activity-log",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// GET SOCIETY REPORTS
		getSocietyReports: builder.query<IGetSocietyReportsResponse, number>({
			query: (societyId) => ({
				url: "/analytics/society_reports_list",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { society_id: societyId }
			})
		}),

		// GET VEHICLE TYPES
		getVehicleTypes: builder.query<IGetVehicleTypesResponse, void>({
			query: () => ({
				url: "/analytics/get-vehicle-types",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// EDIT PROFILE
		editProfile: builder.mutation<IEditProfileResponseData, IEditProfileRequestData>({
			query: (requestData) => ({
				url: `/auth/update-user`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// PASSWORD RESET
		passwordReset: builder.mutation<unknown, string>({
			query: (username) => ({
				url: "/auth/password-reset",
				headers: getAPIHeaderOptions(),
				method: "POST",
				body: { username }
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// PASSWORD RESET CONFIRM
		passwordResetConfirm: builder.mutation<unknown, IPasswordResetConfirmRequestData>({
			query: (requestData) => ({
				url: `/auth/password-reset-confirm/${requestData.uuid}/${requestData.token}`,
				headers: getAPIHeaderOptions(),
				method: "POST",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// GET ALL SOCIETIES LIST
		getAllSocietiesList: builder.query<IGetAllSocietiesResponseData[], void>({
			query: () => ({
				url: "/analytics/society_list",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET ALL DEVICES LIST
		getAllDevicesList: builder.query<IGetAllDevicesListResponseData[], void>({
			query: () => ({
				url: "/devices/device_list",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		})
	})
});

export const {
	// GET OWNER TYPES
	useGetOwnerTypesQuery,
	// GET VISITOR TYPES
	useGetVisitorTypesQuery,
	// GET DEVICES LIST
	useGetDevicesListQuery,
	// GET CAMERA NAMES
	useGetCameraNamesQuery,
	// GET SOCIETY CONFIG
	useGetSocietyConfigQuery,
	// EDIT SOCIETY CONFIG
	useEditSocietyConfigMutation,
	// GET FLATS LIST
	useGetFlatsListQuery,
	// GET CAMERA FRAMES LIST
	useGetCameraFramesListQuery,
	// GET SYSTEM INTEGRATOR DETAILS
	useGetSystemIntegratorDetailsQuery,
	// GET EXCEL VALIDATIONS
	useGetExcelValidationsQuery,
	// GET VEHICLES LIST
	useGetVehiclesListQuery,
	// UPDATE VEHICLE
	useUpdateVehicleMutation,
	// GET USER ACTIVITY LOGS
	useGetUserActivityLogsQuery,
	// GET SOCIETY REPORTS
	useGetSocietyReportsQuery,
	// GET VEHICLE TYPES
	useGetVehicleTypesQuery,
	// EDIT PROFILE
	useEditProfileMutation,
	// PASSWORD RESET
	usePasswordResetMutation,
	// PASSWORD RESET CONFIRM
	usePasswordResetConfirmMutation,
	// GET ALL SOCIETIES LIST
	useGetAllSocietiesListQuery,
	// GET ALL DEVICES LIST
	useGetAllDevicesListQuery
} = settings;
