import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import "./downtimeEventInfoDialog.css";
import { IUptimeHistoryDetails, IUptimeRowDetails } from "../../../types";
import { DataGrid, GridCellParams, GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { PAGINATION_CONSTANTS } from "../../../utils/constants";
import moment from "moment";
import { getFormattedDuration } from "../../../utils/commonUtils";

interface IDowntimeEventInfoDialogProps {
	open: boolean;
	eventDetails: IUptimeRowDetails | null;
	onClose: () => void;
}

const TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const DowntimeEventInfoDialog: FC<IDowntimeEventInfoDialogProps> = (props) => {
	const { open, eventDetails, onClose } = props;

	const columnsData: GridColDef[] = [
		{
			field: "status",
			headerName: "Device Status",
			minWidth: 200,
			flex: 2,
			sortable: false,
			cellClassName: (params: GridCellParams<IUptimeHistoryDetails>) => params.row.status,
			valueFormatter: (params: GridValueFormatterParams<IUptimeHistoryDetails["status"]>) =>
				params.value === "Online" ? "Device Online" : params.value === "Offline" ? "Device Offline" : ""
		},
		{
			field: "from",
			headerName: "From",
			minWidth: 120,
			flex: 1,
			sortable: false,
			valueFormatter: (params) => moment(params.value, TIME_FORMAT).format("hh:mm A"),
			cellClassName: (params: GridCellParams<IUptimeHistoryDetails>) => params.row.status
		},
		{
			field: "to",
			headerName: "To",
			minWidth: 120,
			flex: 1,
			sortable: false,
			valueFormatter: (params) => moment(params.value, TIME_FORMAT).format("hh:mm A"),
			cellClassName: (params: GridCellParams<IUptimeHistoryDetails>) => params.row.status
		},
		{
			field: "duration",
			headerName: "Duration",
			minWidth: 150,
			flex: 1,
			sortable: false,
			valueFormatter: (params) => getFormattedDuration(Number(params.value)),
			cellClassName: (params: GridCellParams<IUptimeHistoryDetails>) => params.row.status
		}
	];

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Downtime Event Information
				<Box className="close-dialog-icon-wrapper">
					<IconButton onClick={onClose}>
						<CloseIcon sx={{ color: "white" }} />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				{eventDetails ? (
					<Box className="downtime-event-info-dialog-content-wrapper">
						<Box className="event-details-date-wrapper">
							<Box className="date-icon-wrapper">
								<CalendarTodayIcon />
							</Box>

							<Typography>{moment(eventDetails.date).format("DD/MM/YYYY")}</Typography>
						</Box>

						<Box className="event-details-wrapper">
							<Box className="event-details-card success">
								<Box className="event-details-card-icon">
									<TimelapseIcon color="inherit" />
								</Box>

								<Box className="event-details-card-text">
									<Typography variant="body2" fontWeight={400} color="#00000080">
										Total Uptime Duration
									</Typography>

									<Typography fontWeight={500}>{eventDetails.uptime}</Typography>
								</Box>
							</Box>

							<Box className="event-details-card error">
								<Box className="event-details-card-icon">
									<TimelapseIcon color="inherit" />
								</Box>

								<Box className="event-details-card-text">
									<Typography variant="body2" fontWeight={400} color="#00000080">
										Total Downtime Duration
									</Typography>

									<Typography fontWeight={500}>{eventDetails.downtime}</Typography>
								</Box>
							</Box>
						</Box>

						<Box className="event-history-table-wrapper">
							<DataGrid
								columns={columnsData}
								rows={eventDetails.history.map((item, index) => ({ ...item, id: index }))}
								disableColumnMenu
								disableRowSelectionOnClick
								density="compact"
								rowSpacingType="border"
								columnHeaderHeight={80}
								pageSizeOptions={[PAGINATION_CONSTANTS.ROWS_PER_PAGE]}
								slots={{
									footer: () => <></>
								}}
								classes={{
									root: "downtime-events-table-root",
									columnHeader: "downtime-events-table-column-header",
									columnSeparator: "downtime-events-table-column-separator"
								}}
							/>
						</Box>
					</Box>
				) : null}
			</DialogContent>
		</Dialog>
	);
};

export default DowntimeEventInfoDialog;
