import { Box, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import "./timeRangeSelection.css";
import moment, { Moment } from "moment";
import CustomRangeSelectionDialog from "./CustomRangeSelectionDialog";
import { API_TIMESTAMP_FORMAT } from "../../utils/constants";
import { TIME_RANGE_OPTIONS_E } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux";
import { setTimeFilter, setTimeRange } from "../../redux/reducers/filters.reducer";

interface ITimeRangeDetails {
	key: TIME_RANGE_OPTIONS_E;
	name: string;
}

export interface ICustomTimeRangeDetails {
	from: Moment;
	to: Moment;
}

interface ITimeRangeSelectionProps {
	customOnly?: boolean;
	useUrlParams?: boolean;
}

const TIME_RANGE_SELECTION_LIST: ITimeRangeDetails[] = [
	{ key: TIME_RANGE_OPTIONS_E.TODAY, name: "Today" },
	{ key: TIME_RANGE_OPTIONS_E.YESTERDAY, name: "Yesterday" },
	{ key: TIME_RANGE_OPTIONS_E.WEEK, name: "Last 7 days" },
	{ key: TIME_RANGE_OPTIONS_E.MONTH, name: "Last Month" },
	{ key: TIME_RANGE_OPTIONS_E.QUARTER, name: "Last 3 Months" },
	{ key: TIME_RANGE_OPTIONS_E.HALF_YEAR, name: "Last 6 Months" }
];

const TimeRangeSelection: FC<ITimeRangeSelectionProps> = (props) => {
	const { customOnly, useUrlParams } = props;

	const dispatch = useAppDispatch();

	const filtersState = useAppSelector((state) => state.filters);
	const { timeFilter, timeRange } = filtersState;

	const dropdownAnchorElementRef = useRef<HTMLButtonElement>(null);

	const [searchParams, setSearchParams] = useSearchParams();

	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [selectedTimeRange, setSelectedTimeRange] = useState<ITimeRangeDetails | null>(
		timeRange ? TIME_RANGE_SELECTION_LIST.find((item) => item.key === timeRange) ?? null : TIME_RANGE_SELECTION_LIST[0]
	);
	const [showCustomRangeSelectionDialog, setShowCustomRangeSelectionDialog] = useState<boolean>(false);
	const [customTimeRange, setCustomTimeRange] = useState<ICustomTimeRangeDetails>({
		from: moment(timeFilter.start_time) ?? moment().startOf("day"),
		to: moment(timeFilter.end_time) ?? moment().endOf("day")
	});

	function handleOpenDropdown() {
		setShowDropdown(true);
	}

	function handleCloseDropdown() {
		setShowDropdown(false);
	}

	function handleOpenCustomRangeSelectionDialog() {
		setShowCustomRangeSelectionDialog(true);
	}

	function handleCloseCustomRangeSelectionDialog() {
		setShowCustomRangeSelectionDialog(false);
	}

	function handleChangeSelectedTimeRange(timeRange: ITimeRangeDetails) {
		setSelectedTimeRange(timeRange);
		handleCloseDropdown();

		const updatedCustomTimeRange = { ...customTimeRange };

		switch (timeRange.key) {
			case TIME_RANGE_OPTIONS_E.TODAY:
				updatedCustomTimeRange.from = moment().startOf("day");
				updatedCustomTimeRange.to = moment().endOf("day");
				break;

			case TIME_RANGE_OPTIONS_E.YESTERDAY:
				updatedCustomTimeRange.from = moment().subtract(1, "day").startOf("day");
				updatedCustomTimeRange.to = moment().subtract(1, "day").endOf("day");
				break;

			case TIME_RANGE_OPTIONS_E.WEEK:
				updatedCustomTimeRange.from = moment().subtract(1, "week").startOf("day");
				updatedCustomTimeRange.to = moment().endOf("day");
				break;

			case TIME_RANGE_OPTIONS_E.MONTH:
				updatedCustomTimeRange.from = moment().subtract(1, "month").startOf("day");
				updatedCustomTimeRange.to = moment().endOf("day");
				break;

			case TIME_RANGE_OPTIONS_E.QUARTER:
				updatedCustomTimeRange.from = moment().subtract(3, "months").startOf("day");
				updatedCustomTimeRange.to = moment().endOf("day");
				break;

			case TIME_RANGE_OPTIONS_E.HALF_YEAR:
				updatedCustomTimeRange.from = moment().subtract(6, "months").startOf("day");
				updatedCustomTimeRange.to = moment().endOf("day");
				break;

			default:
				break;
		}

		setCustomTimeRange(updatedCustomTimeRange);
		dispatch(setTimeRange(timeRange.key));
		dispatch(
			setTimeFilter({
				start_time: updatedCustomTimeRange.from.format(API_TIMESTAMP_FORMAT.TIME_FILTER),
				end_time: updatedCustomTimeRange.to.format(API_TIMESTAMP_FORMAT.TIME_FILTER)
			})
		);
	}

	function handleSelectCustomTimeRange() {
		handleOpenCustomRangeSelectionDialog();
	}

	function handleSubmitCustomRangeSelection(updatedValue: ICustomTimeRangeDetails, timeRange: TIME_RANGE_OPTIONS_E) {
		const selectedTimeRange = TIME_RANGE_SELECTION_LIST.find((item) => item.key === timeRange) ?? null;

		setSelectedTimeRange(selectedTimeRange);
		setCustomTimeRange(updatedValue);
		handleCloseCustomRangeSelectionDialog();
		handleCloseDropdown();

		dispatch(setTimeRange(timeRange));
		dispatch(
			setTimeFilter({
				start_time: updatedValue.from.format(API_TIMESTAMP_FORMAT.TIME_FILTER),
				end_time: updatedValue.to.format(API_TIMESTAMP_FORMAT.TIME_FILTER)
			})
		);

		if (useUrlParams) {
			setSearchParams((params) => {
				params.set("start_time", updatedValue.from.format(API_TIMESTAMP_FORMAT.TIME_FILTER_URL));
				params.set("end_time", updatedValue.to.format(API_TIMESTAMP_FORMAT.TIME_FILTER_URL));
				return params;
			});
		}
	}

	function getTimeRange(startTime: Moment, endTime: Moment): ITimeRangeDetails | null {
		const startTimeString = moment(startTime).format(API_TIMESTAMP_FORMAT.TIME_FILTER);
		const endTimeString = moment(endTime).format(API_TIMESTAMP_FORMAT.TIME_FILTER);

		const todayStart = moment().startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);
		const todayEnd = moment().endOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);

		const yesterdayStart = moment().subtract(1, "day").startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);
		const yesterdayEnd = moment().subtract(1, "day").endOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);

		const weekStart = moment().subtract(1, "week").startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);
		const monthStart = moment().subtract(1, "month").startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);
		const quarterStart = moment().subtract(3, "months").startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);
		const halfYearStart = moment().subtract(6, "months").startOf("day").format(API_TIMESTAMP_FORMAT.TIME_FILTER);

		if (startTimeString === todayStart && endTimeString === todayEnd) {
			return { key: TIME_RANGE_OPTIONS_E.TODAY, name: "Today" };
		}

		if (startTimeString === yesterdayStart && endTimeString === yesterdayEnd) {
			return { key: TIME_RANGE_OPTIONS_E.YESTERDAY, name: "Yesterday" };
		}

		if (startTimeString === weekStart && endTimeString === todayEnd) {
			return { key: TIME_RANGE_OPTIONS_E.WEEK, name: "Last 7 days" };
		}

		if (startTimeString === monthStart && endTimeString === todayEnd) {
			return { key: TIME_RANGE_OPTIONS_E.MONTH, name: "Last Month" };
		}

		if (startTimeString === quarterStart && endTimeString === todayEnd) {
			return { key: TIME_RANGE_OPTIONS_E.QUARTER, name: "Last 3 Months" };
		}

		if (startTimeString === halfYearStart && endTimeString === todayEnd) {
			return { key: TIME_RANGE_OPTIONS_E.HALF_YEAR, name: "Last 6 Months" };
		}

		return null;
	}

	useEffect(() => {
		const startTimeString = searchParams.get("start_time");
		const endTimeString = searchParams.get("end_time");

		if (startTimeString && endTimeString) {
			const startTime = moment(startTimeString, API_TIMESTAMP_FORMAT.TIME_FILTER_URL);
			const endTime = moment(endTimeString, API_TIMESTAMP_FORMAT.TIME_FILTER_URL);

			const selectedTimeRange = getTimeRange(startTime, endTime);

			setSelectedTimeRange(selectedTimeRange);
			setCustomTimeRange({
				from: startTime,
				to: endTime
			});

			dispatch(setTimeRange(selectedTimeRange?.key ?? TIME_RANGE_OPTIONS_E.CUSTOM));
			dispatch(
				setTimeFilter({
					start_time: startTime.format(API_TIMESTAMP_FORMAT.TIME_FILTER),
					end_time: endTime.format(API_TIMESTAMP_FORMAT.TIME_FILTER)
				})
			);
		}
	}, [dispatch, searchParams]);

	return (
		<>
			<Box
				component="div"
				className="time-range-selection-button"
				ref={dropdownAnchorElementRef}
				aria-haspopup="true"
				id="time-range-selection-dropdown-button"
				onClick={customOnly ? handleSelectCustomTimeRange : handleOpenDropdown}
				aria-expanded={showDropdown}
				aria-controls="time-range-selection-dropdown-menu"
			>
				<Box className="time-range-selection-button-text">
					<CalendarTodayIcon color="inherit" fontSize="small" />
					<Typography fontWeight={500}>
						{selectedTimeRange
							? selectedTimeRange.name
							: `${customTimeRange.from.format("DD/MM/YYYY")} - ${customTimeRange.to.format("DD/MM/YYYY")}`}
					</Typography>
				</Box>

				{!customOnly ? (
					showDropdown ? (
						<ArrowDropUpIcon color="inherit" fontSize="small" />
					) : (
						<ArrowDropDownIcon color="inherit" fontSize="small" />
					)
				) : null}
			</Box>

			{!customOnly ? (
				<Menu
					disableAutoFocusItem
					id="time-range-selection-dropdown-menu"
					anchorEl={dropdownAnchorElementRef.current}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					transformOrigin={{ vertical: "top", horizontal: "center" }}
					open={showDropdown}
					onClose={handleCloseDropdown}
					MenuListProps={{ disablePadding: true, "aria-labelledby": "time-range-selection-dropdown-button" }}
					slotProps={{ paper: { elevation: 1 } }}
				>
					{TIME_RANGE_SELECTION_LIST.map((timeRangeItem) => (
						<MenuItem
							divider
							key={timeRangeItem.key}
							onClick={() => handleChangeSelectedTimeRange(timeRangeItem)}
							classes={{ root: "time-range-selection-dropdown-menu-item" }}
						>
							{timeRangeItem.name}
						</MenuItem>
					))}

					<MenuItem onClick={handleSelectCustomTimeRange} classes={{ root: "time-range-selection-dropdown-menu-item" }}>
						<ListItemIcon classes={{ root: "time-range-selection-dropdown-menu-item-icon" }}>
							<CalendarTodayIcon fontSize="small" />
						</ListItemIcon>
						Custom Date
					</MenuItem>
				</Menu>
			) : null}

			<CustomRangeSelectionDialog
				showPredefinedValues={customOnly}
				open={showCustomRangeSelectionDialog}
				defaultValues={customTimeRange}
				defaultPredefinedValue={selectedTimeRange?.key}
				onClose={handleCloseCustomRangeSelectionDialog}
				onSubmit={handleSubmitCustomRangeSelection}
			/>
		</>
	);
};

export default TimeRangeSelection;
