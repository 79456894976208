import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueFormatterParams
} from "@mui/x-data-grid";
import moment from "moment";
import React, { FC, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import "./userActivityLog.css";
import { PAGINATION_CONSTANTS } from "../../../utils/constants";
import { IGetUserActivityLogsRequestData } from "../../../types";
import TablePagination from "../../../components/TablePagination";
import UserActivityDetailsDialog from "./UserActivityDetailsDialog";
import { useGetUserActivityLogsQuery } from "../../../redux/reducers/settings.reducer";

export interface IUserActivityDetails {
	id: number;
	sr_no: number;
	timestamp: string;
	user_name: string;
	activity: string;
}

const UserActivityLogsPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const UserActivityLog: FC = () => {
	const [filters, setFilters] = useState<IGetUserActivityLogsRequestData>({ page: 1 });
	const [selectedActivityLog, setSelectedActivityLog] = useState<IUserActivityDetails | null>(null);

	// APIS
	// GET USER ACTIVITY LOGS
	const {
		data: getUserActivityLogsResponse,
		isLoading: getUserActivityLogsLoading,
		isFetching: getUserActivityLogsFetching
	} = useGetUserActivityLogsQuery(filters);

	const rowsData = useMemo<IUserActivityDetails[]>(() => {
		if (getUserActivityLogsResponse) {
			return getUserActivityLogsResponse.results.map((result, index) => ({
				id: result.id,
				sr_no: (filters.page - 1) * PAGINATION_CONSTANTS.ROWS_PER_PAGE + index + 1,
				timestamp: result.timestamp,
				user_name: result.name,
				activity: result.activity
			}));
		}

		return [];
	}, [filters.page, getUserActivityLogsResponse]);

	function handlePageChange(event: GridPaginationModel) {
		setFilters((currentFilters) => ({
			...currentFilters,
			page: event.page + 1
		}));
	}

	function handleOpenActivityDetailsDialog(activityDetails: IUserActivityDetails) {
		setSelectedActivityLog(activityDetails);
	}

	function handleCloseActivityDetailsDialog() {
		setSelectedActivityLog(null);
	}

	const columnsData: GridColDef[] = [
		{
			field: "sr_no",
			headerName: "Sr. No.",
			width: 75,
			sortable: false,
			align: "center",
			headerAlign: "center"
		},
		{
			field: "timestamp",
			headerName: "Timestamp",
			flex: 1,
			minWidth: 150,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<string>) => moment(params.value).format("DD/MM/YYYY hh:mm A")
		},
		{
			field: "user_name",
			headerName: "User Name",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "activity",
			headerName: "Activity",
			flex: 1,
			minWidth: 200,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IUserActivityDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton size="small" color="info" onClick={() => handleOpenActivityDetailsDialog(params.row)}>
						<InfoOutlined fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="user-activity-log-screen-wrapper">
			<Box className="user-activity-log-screen-header">
				<Typography variant="h6">User Activities</Typography>
			</Box>

			<Box className="user-activity-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={rowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					paginationMode="server"
					pageSizeOptions={[PAGINATION_CONSTANTS.ROWS_PER_PAGE]}
					onPaginationModelChange={handlePageChange}
					rowCount={getUserActivityLogsResponse?.count ?? 0}
					paginationModel={{ page: filters.page - 1, pageSize: PAGINATION_CONSTANTS.ROWS_PER_PAGE }}
					hideFooter={(getUserActivityLogsResponse?.count ?? 0) <= PAGINATION_CONSTANTS.ROWS_PER_PAGE}
					loading={getUserActivityLogsLoading || getUserActivityLogsFetching}
					classes={{
						columnHeader: "user-activity-table-column-header",
						columnSeparator: "user-activity-table-column-separator"
					}}
					slots={{ pagination: UserActivityLogsPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
				/>
			</Box>

			<UserActivityDetailsDialog
				open={!!selectedActivityLog}
				activityDetails={selectedActivityLog}
				onClose={handleCloseActivityDetailsDialog}
			/>
		</Box>
	);
};

export default UserActivityLog;
