import React, { FC, SVGAttributes } from "react";
import { VEHICLE_TYPE_E } from "../../utils/constants";
import { ReactComponent as BikeIcon } from "../../assets/icons/bike-icon.svg";
import { ReactComponent as CarIcon } from "../../assets/icons/car-icon.svg";
import { ReactComponent as BusIcon } from "../../assets/icons/bus-icon.svg";
import { ReactComponent as TruckIcon } from "../../assets/icons/truck-icon.svg";
import { ReactComponent as RickshawIcon } from "../../assets/icons/rickshaw.svg";
import { ReactComponent as OthersIcon } from "../../assets/icons/others.svg";

interface IVehicleTypeIconProps extends SVGAttributes<SVGSVGElement> {
	vehicleType: VEHICLE_TYPE_E;
}

const VehicleTypeIcon: FC<IVehicleTypeIconProps> = (props) => {
	const { vehicleType, ...restProps } = props;

	if (vehicleType === VEHICLE_TYPE_E.TWO_WHEELER) {
		return <BikeIcon {...restProps} />;
	}

	if (vehicleType === VEHICLE_TYPE_E.BUS) {
		return <BusIcon {...restProps} />;
	}

	if (vehicleType === VEHICLE_TYPE_E.TRUCK) {
		return <TruckIcon {...restProps} />;
	}

	if (vehicleType === VEHICLE_TYPE_E.THREE_WHEELER) {
		return <RickshawIcon {...restProps} />;
	}

	if (vehicleType === VEHICLE_TYPE_E.OTHERS) {
		return <OthersIcon {...restProps} />;
	}

	return <CarIcon {...restProps} />;
};

export default VehicleTypeIcon;
