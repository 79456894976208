import { Box, Button, Container, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "./resetPassword.css";
import { ReactComponent as GGConnectLogo } from "../../assets/logos/gg-connect-logo-new.svg";
import { ReactComponent as GGConnectLoginImage } from "../../assets/images/gg-connect-login.svg";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { usePasswordResetConfirmMutation } from "../../redux/reducers/settings.reducer";
import { useAppDispatch, useAppSelector } from "../../redux";
import { updatePasswordResetDetails } from "../../redux/reducers/auth.reducer";

interface IResetPasswordInputs {
	password: string;
	confirm_password: string;
}

const SUCCESS_REDIRECTION_TIMEOUT = 5; // IN SECONDS

const ResetPassword: FC = () => {
	const { uuid, token } = useParams();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const authState = useAppSelector((state) => state.auth);

	const [passwordVisibility, setPasswordVisibility] = useState<{ [key in keyof IResetPasswordInputs]: boolean }>({
		password: false,
		confirm_password: false
	});

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IResetPasswordInputs>();

	// APIS
	// PASSWORD RESET CONFIRM
	const [
		passwordResetConfirm,
		{
			isLoading: passwordResetConfirmLoading,
			error: passwordResetConfirmError,
			data: passwordResetConfirmResponse,
			reset: resetPasswordResetConfirmState
		}
	] = usePasswordResetConfirmMutation();

	function handleSubmitForm(formData: IResetPasswordInputs) {
		if (!uuid || !token) return;

		passwordResetConfirm({
			uuid: uuid,
			token: token,
			data: { password: formData.password }
		});
	}

	function togglePasswordVisibility(key: keyof IResetPasswordInputs) {
		setPasswordVisibility((currentVisibility) => ({
			...currentVisibility,
			[key]: !currentVisibility[key]
		}));
	}

	function navigateToLogin() {
		navigate("/login");
	}

	useEffect(() => {
		if (authState.passwordReset && authState.passwordReset.uuid === uuid && authState.passwordReset.token === token) {
			dispatch(updatePasswordResetDetails(null));

			if (authState.passwordReset.success) {
				navigate("/login");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (passwordResetConfirmResponse && uuid && token) {
			dispatch(
				updatePasswordResetDetails({
					uuid: uuid,
					token: token,
					success: true
				})
			);
			resetPasswordResetConfirmState();

			setTimeout(() => {
				dispatch(updatePasswordResetDetails(null));
				navigate("/login");
			}, SUCCESS_REDIRECTION_TIMEOUT * 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordResetConfirmResponse, token, uuid]);

	return (
		<Box className="reset-password-component-wrapper">
			<Container maxWidth="xl" sx={{ height: "100%" }}>
				<Box className="reset-password-content-wrapper">
					<Box className="reset-password-image-section">
						<GGConnectLogo width="85%" />
						<GGConnectLoginImage width="90%" />
					</Box>

					{authState.passwordReset && authState.passwordReset.success ? (
						<Box className="reset-password-form-section">
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									gap: "1rem"
								}}
							>
								<Box
									sx={{
										backgroundColor: "var(--color-success-light)",
										padding: "0.25rem 0.5rem",
										border: "1px solid var(--color-success-main)",
										borderRadius: "4px"
									}}
								>
									<Typography variant="h6" textAlign="center" color="var(--color-success-main)">
										Password Reset Successfully!
									</Typography>
								</Box>

								<Button disableElevation variant="contained" color="success" onClick={navigateToLogin}>
									Go To Login
								</Button>
							</Box>
						</Box>
					) : (
						<Box className="reset-password-form-section">
							<Typography variant="h4" color="#F4F4FE" fontWeight={500}>
								Reset Password
							</Typography>

							<Box
								className="reset-password-form-wrapper"
								component="form"
								noValidate
								onSubmit={handleSubmit(handleSubmitForm)}
							>
								<Box className="reset-password-input-wrapper">
									<Typography variant="h6" fontWeight={400} color="inherit">
										New Password
									</Typography>

									<TextField
										required
										fullWidth
										variant="filled"
										color="primary"
										type={passwordVisibility.password ? "text" : "password"}
										placeholder="Enter new password"
										error={!!errors.password}
										helperText={errors.password && errors.password.message ? errors.password.message.toString() : ""}
										InputProps={{
											hiddenLabel: true,
											disableUnderline: true,
											classes: { root: "reset-password-input-root" },
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end" onClick={() => togglePasswordVisibility("password")} tabIndex={-1}>
														{passwordVisibility.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											)
										}}
										{...register("password", { required: "New password is required" })}
									/>
								</Box>

								<Box className="reset-password-input-wrapper">
									<Typography variant="h6" fontWeight={400} color="inherit">
										Confirm Password
									</Typography>

									<TextField
										required
										fullWidth
										variant="filled"
										color="primary"
										type={passwordVisibility.confirm_password ? "text" : "password"}
										placeholder="Re-enter new password"
										error={!!errors.confirm_password}
										helperText={
											errors.confirm_password && errors.confirm_password.message
												? errors.confirm_password.message.toString()
												: ""
										}
										InputProps={{
											hiddenLabel: true,
											disableUnderline: true,
											classes: { root: "reset-password-input-root" },
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														edge="end"
														onClick={() => togglePasswordVisibility("confirm_password")}
														tabIndex={-1}
													>
														{passwordVisibility.confirm_password ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											)
										}}
										{...register("confirm_password", {
											required: "Confirm password is required",
											validate: (value, formData) => {
												if (value !== formData.password) return "Confirm password should be same as password";
												return true;
											}
										})}
									/>
								</Box>

								{passwordResetConfirmError ? (
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Box
											sx={{
												backgroundColor: "var(--color-error-light)",
												padding: "0.25rem 0.5rem",
												border: "1px solid var(--color-error-main)",
												borderRadius: "4px"
											}}
										>
											<Typography variant="body2" textAlign="center" color="var(--color-error-main)">
												{"data" in passwordResetConfirmError && passwordResetConfirmError.data
													? String(passwordResetConfirmError.data)
													: "Something went wrong"}
											</Typography>
										</Box>
									</Box>
								) : null}

								<LoadingButton
									disableElevation
									variant="contained"
									color="success"
									sx={{ paddingY: 1.5, textTransform: "capitalize" }}
									type="submit"
									loading={passwordResetConfirmLoading}
								>
									Submit
								</LoadingButton>
							</Box>
						</Box>
					)}

					<Box className="reset-password-form-background" />
				</Box>
			</Container>
		</Box>
	);
};

export default ResetPassword;
