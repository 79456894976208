import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	Popover,
	TextField,
	Typography,
	styled
} from "@mui/material";
import React, { FC, FormEvent, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { LoadingButton } from "@mui/lab";
import {
	GridCellParams,
	GridColDef,
	GridFilterItem,
	GridLogicOperator,
	GridRenderCellParams,
	GridRowClassNameParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel
} from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadIcon from "@mui/icons-material/Download";
import ContactsIcon from "@mui/icons-material/Contacts";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import UndoIcon from "@mui/icons-material/Undo";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import "./newUploadDataDialog.css";
import { ReactComponent as IncorrectEntriesIcon } from "../../assets/icons/incorrect-entries.svg";
import {
	DUPLICATE_VEHICLE_NUMBER_ERROR_MESSAGE,
	handleProcessEditedRowDetails,
	handleProcessFileData,
	handleRevalidateRows
} from "../../utils/uploadDataUtils";
import { CombinedRowType, IListsData, IUploadResponse } from "../../types";
import { ReactComponent as DuplicateIcon } from "../../assets/icons/duplicate.svg";
import {
	useGetDevicesListQuery,
	useGetExcelValidationsQuery,
	useGetFlatsListQuery,
	useGetOwnerTypesQuery,
	useGetVehicleTypesQuery
} from "../../redux/reducers/settings.reducer";
import downloadServices from "../../redux/services/download.services";
import ConfirmationDialog from "../ConfirmationDialog";
import { DEFAULT_VALIDATIONS } from "../../utils/constants";

interface INewUploadDataDialogProps {
	open: boolean;
	sampleFileUrl: string;
	sampleFileName?: string;
	localSampleFile?: boolean;
	uploadButtonLoading?: boolean;
	disableCustomFlatNumber?: boolean;
	onClose: () => void;
	onUploadButtonClick: (
		rowsData: CombinedRowType[],
		isCustomFlatNumber: boolean,
		devices: number[],
		isVerified?: boolean
	) => Promise<IUploadResponse>;
}

enum UPLOAD_EXCEL_STEP_E {
	UPLOAD,
	VALIDATION,
	AMBIGUITY_EXCEL,
	AMBIGUITY_DB
}

type StepRendererType = {
	[key in UPLOAD_EXCEL_STEP_E]: ReactNode;
};

enum UPLOAD_ERROR_MESSAGES_E {
	PARTIALLY_CREATED = "Failed to create some records. Please check the response for more details.",
	SUCCESSFULLY_CREATED = "Successfully created / updated all the data.",
	DUPLICATES_FOUND = "Duplicate records found. Please check the response for more details.",
	DIRECTLY_CREATED = "Successfully created / updated all the data."
}

const StyledBox = styled(Box)(({ theme }) => ({
	width: "100%",
	"& .MuiDataGrid-cell--editable": {
		"& .MuiInputBase-root": {
			height: "100%"
		}
	},
	"& .Mui-error": {
		backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
		color: "var(--color-error-main)"
	}
}));

const NewUploadDataDialog: FC<INewUploadDataDialogProps> = (props) => {
	const {
		open,
		localSampleFile,
		sampleFileUrl,
		sampleFileName,
		uploadButtonLoading,
		disableCustomFlatNumber,
		onClose,
		onUploadButtonClick
	} = props;

	const validationTableWrapperRef = useRef<HTMLDivElement>(null);
	const ambiguityTableRef = useRef<HTMLDivElement>(null);
	const dbAmbiguityTableRef = useRef<HTMLDivElement>(null);

	const validationTableRef = useGridApiRef();

	const [fileInput, setFileInput] = useState<File | null>(null);
	const [showSampleFileDownloadLoader, setShowSampleFileDownloadLoader] = useState<boolean>(false);
	const [currentStep, setCurrentStep] = useState<UPLOAD_EXCEL_STEP_E>(UPLOAD_EXCEL_STEP_E.UPLOAD);
	const [showUploadButtonLoading, setShowUploadButtonLoading] = useState<boolean>(false);
	const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState<boolean>(false);
	const [showInvalidExcelError, setShowInvalidExcelError] = useState<boolean>(false);

	const [columnsData, setColumnsData] = useState<GridColDef[]>([]);
	const [rowsData, setRowsData] = useState<CombinedRowType[]>([]);
	const [skippedRowsData, setSkippedRowsData] = useState<CombinedRowType[]>([]);

	const [selectedFilter, setSelectedFilter] = useState<"all" | "correct" | "incorrect">("all");

	const [errorDialogMessage, setErrorDialogMessage] = useState<string>("");

	const [customFlatSelectionEnabled, setCustomFlatSelectionEnabled] = useState<boolean>(false);

	const [columnFilterName, setColumnFilterName] = useState<string>("");
	const [columnFilterAnchorElement, setColumnFilterAnchorElement] = useState<HTMLDivElement | null>(null);
	const columnFilterOpen = Boolean(columnFilterAnchorElement);
	const [columnFilterInput, setColumnFilterInput] = useState<string>("");
	const [columnFilters, setColumnFilters] = useState<GridFilterItem[]>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

	const [filterDropdownAnchorElement, setFilterDropdownAnchorElement] = useState<HTMLDivElement | null>(null);
	const filterDropdownOpen = Boolean(filterDropdownAnchorElement);

	const [selectedDevices, setSelectedDevices] = useState<number[]>([]);

	// APIS
	const { data: excelValidations } = useGetExcelValidationsQuery();

	const { data: getDevicesListResponse } = useGetDevicesListQuery({});
	const devicesList = getDevicesListResponse ? getDevicesListResponse.results : [];

	const { data: getOwnerTypesListResponse } = useGetOwnerTypesQuery();
	const ownerTypesList = useMemo(
		() => (getOwnerTypesListResponse ? getOwnerTypesListResponse.results : []),
		[getOwnerTypesListResponse]
	);

	// GET VEHICLE TYPES
	const { data: getVehicleTypesResponse } = useGetVehicleTypesQuery();

	const vehicleTypesList = useMemo(() => {
		if (getVehicleTypesResponse) {
			return Object.entries(getVehicleTypesResponse).map(([id, name]) => ({
				id: Number(id),
				name: name
			}));
		}

		return [];
	}, [getVehicleTypesResponse]);

	const { data: flatsList } = useGetFlatsListQuery();

	const listsData = useMemo<IListsData>(
		() => ({
			flatsList: flatsList ?? [],
			ownerTypesList: ownerTypesList,
			vehicleTypesList: vehicleTypesList
		}),
		[flatsList, ownerTypesList, vehicleTypesList]
	);

	const FilteredRowsData = useMemo<CombinedRowType[]>(() => {
		if (selectedFilter === "all") return [...rowsData];

		if (selectedFilter === "correct") {
			return [...rowsData].filter((item) => !item.errorFields || Object.keys(item.errorFields).length <= 0);
		}

		if (selectedFilter === "incorrect") {
			return [...rowsData].filter((item) => item.errorFields && Object.keys(item.errorFields).length > 0);
		}

		return [];
	}, [rowsData, selectedFilter]);

	const DuplicateRows = useMemo(() => {
		const duplicateRows: CombinedRowType[] = [];

		for (const rowDetails of rowsData) {
			if (
				rowDetails.errorFields &&
				rowDetails.errorFields["Number Plate"] &&
				rowDetails.errorFields["Number Plate"].includes(DUPLICATE_VEHICLE_NUMBER_ERROR_MESSAGE)
			) {
				duplicateRows.push(rowDetails);
			}
		}

		return duplicateRows;
	}, [rowsData]);

	const UploadedDataMetrics = useMemo(() => {
		const filteredRows = [...rowsData].filter((item) => !item.is_deleted);

		const totalEntries = filteredRows.length;
		const incorrectEntries = filteredRows.reduce(
			(count, rowItem) => (rowItem.errorFields && Object.keys(rowItem.errorFields).length > 0 ? count + 1 : count),
			0
		);

		const correctEntries = totalEntries - incorrectEntries;

		return { correctEntries, incorrectEntries, totalEntries };
	}, [rowsData]);

	const AllDevicesSelected = useMemo<"none" | "all" | "partial">(() => {
		if (selectedDevices.length === 0) return "none";
		if (selectedDevices.length === devicesList.length) return "all";
		return "partial";
	}, [devicesList.length, selectedDevices.length]);

	function handleChangeSelectedDevices(updatedValue: number[]) {
		setSelectedDevices(updatedValue);
	}

	function getModifiedColumnsData(disableEdit = false) {
		const updatedColumnsData = columnsData.map((columnItem) => {
			const appliedFilter = columnFilters.find((filterItem) => filterItem.field === columnItem.field);

			if (appliedFilter) {
				return {
					...columnItem,
					renderHeader: () => (
						<Box sx={{ width: "100%" }}>
							<Box component="div" className="upload-data-column-filter-wrapper">
								<Typography variant="subtitle2" overflow="hidden" textOverflow="ellipsis" flexGrow={1}>
									{columnItem.field}
								</Typography>

								<IconButton
									size="small"
									onClick={(event) => {
										handleOpenColumnFilter(event.currentTarget.parentElement as HTMLDivElement, columnItem.field);
									}}
								>
									<SearchIcon fontSize="small" />
								</IconButton>
							</Box>

							<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
								<Typography variant="subtitle2">{`Search (${appliedFilter.value})`}</Typography>

								{/* <IconButton size="small"> */}
								<CloseIcon
									fontSize="small"
									cursor="pointer"
									onClick={() => handleClearColumnFilter(appliedFilter.field)}
								/>
								{/* </IconButton> */}
							</Box>
						</Box>
					)
				};
			}

			return columnItem;
		});

		updatedColumnsData.unshift({
			field: "sr_no",
			width: 100,
			headerName: "Sr. No.",
			colSpan: (params: GridCellParams<CombinedRowType>) => {
				if (params.row.is_deleted) return Object.keys(params.row).length;
				return 1;
			},
			renderCell: (params: GridRenderCellParams<CombinedRowType>) => {
				if (params.row.is_deleted) {
					return (
						<Box
							sx={{
								width: "100%",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between"
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center", gap: "1.5rem", paddingX: "2rem" }}>
								<ReportProblemIcon color="error" />
								<Typography variant="body2">The row has been deleted</Typography>
							</Box>

							<IconButton size="small" color="primary" onClick={() => handleUndoRowDelete(params.row.id)}>
								<UndoIcon fontSize="small" />
							</IconButton>
						</Box>
					);
				}

				return undefined;
			}
		});

		updatedColumnsData.push({
			field: "actions",
			width: 75,
			headerName: "",
			renderCell: (params: GridRenderCellParams<CombinedRowType>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					{rowModesModel[params.row.id] && rowModesModel[params.row.id].mode === GridRowModes.Edit ? (
						<>
							<IconButton size="small" color="success" onClick={() => handleSaveButtonClick(params.row.id)}>
								<SaveIcon fontSize="small" />
							</IconButton>

							<IconButton size="small" onClick={() => handleCancelButtonClick(params.row.id)}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</>
					) : (
						<>
							{!disableEdit ? (
								<IconButton size="small" onClick={() => handleEditButtonClick(params.row.id)}>
									<EditIcon fontSize="small" />
								</IconButton>
							) : null}

							<IconButton size="small" color="error" onClick={() => handleDeleteRow(params.row.id)}>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</>
					)}
				</Box>
			)
		});

		return updatedColumnsData;
	}

	function handleChangeColumnFilterInput(updatedValue: string) {
		setColumnFilterInput(updatedValue);
	}

	function handleOpenColumnFilter(anchorElement: HTMLDivElement, columnName: string) {
		setColumnFilterAnchorElement(anchorElement);
		setColumnFilterName(columnName);

		function callback(input: string) {
			setColumnFilterInput(input);
		}

		setColumnFilters((currentFilters) => {
			const selectedColumn = currentFilters.find((item) => item.field === columnName);

			if (selectedColumn) {
				callback(selectedColumn.value);
			} else {
				callback("");
			}

			return currentFilters;
		});
	}

	function handleCloseColumnFilter() {
		setColumnFilterAnchorElement(null);
		setColumnFilterName("");
		setColumnFilterInput("");
	}

	function handleSearchButtonClick(event: FormEvent) {
		event.preventDefault();

		if (columnFilterInput) {
			setColumnFilters((currentFilters) => {
				const updatedFilters = [...currentFilters];

				const existingFilterIndex = updatedFilters.findIndex((item) => item.field === columnFilterName);

				if (existingFilterIndex >= 0) {
					updatedFilters[existingFilterIndex] = {
						...updatedFilters[existingFilterIndex],
						value: columnFilterInput,
						operator: !customFlatSelectionEnabled && columnFilterName === "Flat Number" ? "is" : "contains"
					};
				} else {
					updatedFilters.push({
						field: columnFilterName,
						operator: !customFlatSelectionEnabled && columnFilterName === "Flat Number" ? "is" : "contains",
						value: columnFilterInput
					});
				}

				return updatedFilters;
			});

			// validationTableRef.current.setFilterModel({});

			handleCloseColumnFilter();
		}
	}

	function handleClearColumnFilter(columnName: string) {
		setColumnFilters((currentFilters) => {
			return currentFilters.filter((item) => item.field !== columnName);
		});
	}

	function handleRowModesModelChange(newRowModesModel: GridRowModesModel) {
		setRowModesModel(newRowModesModel);
	}

	function handleEditButtonClick(rowId: number) {
		setRowModesModel((currentRowModesModel) => ({
			...currentRowModesModel,
			[rowId]: { mode: GridRowModes.Edit }
		}));
	}

	function handleSaveButtonClick(rowId: number) {
		setRowModesModel((currentRowModesModel) => ({
			...currentRowModesModel,
			[rowId]: { mode: GridRowModes.View }
		}));
	}

	function handleCancelButtonClick(rowId: number) {
		setRowModesModel((currentRowModesModel) => ({
			...currentRowModesModel,
			[rowId]: { mode: GridRowModes.View, ignoreModifications: true }
		}));
	}

	const handleUploadData = useCallback(
		(isVerified?: boolean) => {
			onUploadButtonClick([...rowsData], customFlatSelectionEnabled, selectedDevices, isVerified)
				.then((response) => {
					const responseMessage = response.message as UPLOAD_ERROR_MESSAGES_E;

					switch (responseMessage) {
						case UPLOAD_ERROR_MESSAGES_E.SUCCESSFULLY_CREATED:
						case UPLOAD_ERROR_MESSAGES_E.DIRECTLY_CREATED:
							if (response.data.length <= 0) onClose();
							break;

						case UPLOAD_ERROR_MESSAGES_E.PARTIALLY_CREATED:
						case UPLOAD_ERROR_MESSAGES_E.DUPLICATES_FOUND:
							if (response.data.length <= 0 && !isVerified) {
								handleUploadData(true);
							} else {
								setRowsData((currentRowsData) => {
									const updatedRowsData = currentRowsData.filter((item) => !item.is_deleted);

									let rowId = (updatedRowsData[updatedRowsData.length - 1]?.id ?? 0) + 1;

									for (const responseItem of response.data) {
										let insertIndex = -1;

										if (
											responseItem.remarks === "Vehicle Duplicates" ||
											responseItem.remarks === "Vehicle and Owner Both Duplicate"
										) {
											insertIndex = updatedRowsData.findIndex(
												(item) => item["Number Plate"] === responseItem["Number Plate"]
											);
										} else if (responseItem.remarks === "Owner Duplicate") {
											insertIndex = updatedRowsData.findIndex(
												(item) => item["Contact Number"] === responseItem["Contact Number"]
											);

											if (insertIndex < 0) {
												insertIndex = updatedRowsData.findIndex(
													(item) => item["Vehicle Owner Name"] === responseItem["Vehicle Owner Name"]
												);

												if (insertIndex < 0) {
													insertIndex = updatedRowsData.findIndex(
														(item) => item["Flat Number"] === responseItem["Flat Number"]
													);
												}
											}
										}

										responseItem.id = rowId;
										responseItem.sr_no = rowId + 1;

										if (insertIndex >= 0) {
											updatedRowsData.splice(insertIndex + 1, 0, responseItem);
										} else {
											updatedRowsData.push(responseItem);
										}

										rowId += 1;
									}

									return updatedRowsData;
								});

								setCurrentStep(UPLOAD_EXCEL_STEP_E.AMBIGUITY_DB);
							}
							break;
					}
				})
				.catch((error) => {
					console.error("error", error);
				});
		},
		[customFlatSelectionEnabled, onClose, onUploadButtonClick, rowsData, selectedDevices]
	);

	function handleNextStep() {
		switch (currentStep) {
			case UPLOAD_EXCEL_STEP_E.UPLOAD:
				setCurrentStep(UPLOAD_EXCEL_STEP_E.VALIDATION);
				break;

			case UPLOAD_EXCEL_STEP_E.VALIDATION:
				const filteredRows = [...rowsData].filter((rowItem) => !rowItem.is_deleted);

				const duplicateRecordsPresent = filteredRows.some((rowItem) => {
					return !!filteredRows.find(
						(item) => item["Number Plate"] && rowItem.id !== item.id && rowItem["Number Plate"] === item["Number Plate"]
					);
				});

				if (duplicateRecordsPresent) setCurrentStep(UPLOAD_EXCEL_STEP_E.AMBIGUITY_EXCEL);
				else handleUploadData();

				break;

			case UPLOAD_EXCEL_STEP_E.AMBIGUITY_EXCEL:
				handleUploadData();
				break;

			case UPLOAD_EXCEL_STEP_E.AMBIGUITY_DB:
				handleUploadData(true);
				break;

			default:
				break;
		}
	}

	function handlePreviousStep() {
		setCurrentStep((currentStepValue) => {
			switch (currentStepValue) {
				case UPLOAD_EXCEL_STEP_E.AMBIGUITY_DB:
				case UPLOAD_EXCEL_STEP_E.AMBIGUITY_EXCEL:
					return UPLOAD_EXCEL_STEP_E.VALIDATION;

				case UPLOAD_EXCEL_STEP_E.VALIDATION:
					return UPLOAD_EXCEL_STEP_E.UPLOAD;

				case UPLOAD_EXCEL_STEP_E.UPLOAD:
				default:
					return currentStepValue;
			}
		});
	}

	function handleOpenErrorDialog(message: string) {
		setErrorDialogMessage(message);
	}

	function handleCloseErrorDialog() {
		setErrorDialogMessage("");
	}

	function handleOpenFilterDropdown(event: MouseEvent<HTMLDivElement>) {
		setFilterDropdownAnchorElement(event.currentTarget);
	}

	function handleCloseFilterDropdown() {
		setFilterDropdownAnchorElement(null);
	}

	function handleChangeSelectedFilter(updatedValue: "all" | "correct" | "incorrect") {
		setSelectedFilter(updatedValue);
		handleCloseFilterDropdown();
	}

	function handleDeleteRow(rowId: number) {
		setRowsData((currentRowsData) => {
			const updatedRowsData = [...currentRowsData];

			const rowIndex = updatedRowsData.findIndex((rowItem) => rowItem.id === rowId);

			if (rowIndex >= 0) {
				updatedRowsData[rowIndex] = {
					...updatedRowsData[rowIndex],
					is_deleted: true
				};
			}

			const updatedData = handleRevalidateRows(
				updatedRowsData,
				columnsData,
				listsData,
				excelValidations ?? DEFAULT_VALIDATIONS,
				customFlatSelectionEnabled,
				handleOpenErrorDialog,
				handleOpenColumnFilter
			);

			return updatedData.rows;
		});
	}

	function handleUndoRowDelete(rowId: number) {
		setRowsData((currentRowsData) => {
			const updatedRowsData = [...currentRowsData];

			const rowIndex = updatedRowsData.findIndex((rowItem) => rowItem.id === rowId);

			if (rowIndex >= 0) {
				updatedRowsData[rowIndex] = {
					...updatedRowsData[rowIndex],
					is_deleted: false
				};
			}

			const updatedData = handleRevalidateRows(
				updatedRowsData,
				columnsData,
				listsData,
				excelValidations ?? DEFAULT_VALIDATIONS,
				customFlatSelectionEnabled,
				handleOpenErrorDialog,
				handleOpenColumnFilter
			);

			return updatedData.rows;
		});
	}

	function handleExcelFileUpload(file: File) {
		setFileInput(file);

		const listsData: IListsData = {
			ownerTypesList: ownerTypesList,
			flatsList: flatsList ?? [],
			vehicleTypesList: vehicleTypesList
		};

		setShowUploadButtonLoading(true);

		file
			.arrayBuffer()
			.then((fileArrayBuffer) => {
				const { rows, columns, skippedRows } = handleProcessFileData(
					fileArrayBuffer,
					listsData,
					excelValidations ?? DEFAULT_VALIDATIONS,
					customFlatSelectionEnabled,
					handleOpenErrorDialog,
					handleOpenColumnFilter
				);

				const uploadedRowsData = rows as CombinedRowType[];

				const validityRequiredOwnerTypes = ownerTypesList
					.filter((ownerTypeItem) => ownerTypeItem.has_expiry)
					.map((ownerTypeItem) => ownerTypeItem.type_of);

				const isTenantPresent = uploadedRowsData.some((rowItem) =>
					validityRequiredOwnerTypes.includes(rowItem["Owner Type"])
				);

				if (isTenantPresent) {
					const validityStartDateColumn = columns.find((columnItem) => columnItem.field === "Validity Start Date");
					const validityEndDateColumn = columns.find((columnItem) => columnItem.field === "Validity End Date");

					if (!validityStartDateColumn || !validityEndDateColumn) {
						setShowInvalidExcelError(true);
						return;
					}
				}

				setColumnsData(columns);
				setRowsData(uploadedRowsData);
				setSkippedRowsData(skippedRows as CombinedRowType[]);
				setShowInvalidExcelError(false);

				handleNextStep();
			})
			.catch((error) => {
				console.error("COULD NOT READ FILE ::", error);
			})
			.finally(() => {
				setShowUploadButtonLoading(false);
			});
	}

	function handleRemoveFile() {
		setFileInput(null);
		setShowInvalidExcelError(false);
	}

	function handleDownloadSampleFile() {
		if (localSampleFile) {
			const downloadLinkElement = document.createElement("a");

			downloadLinkElement.href = sampleFileUrl;
			downloadLinkElement.setAttribute("download", sampleFileName ?? "Sample.xlsx");

			document.body.appendChild(downloadLinkElement);

			downloadLinkElement.click();
			downloadLinkElement.parentNode?.removeChild(downloadLinkElement);
		} else {
			setShowSampleFileDownloadLoader(true);
			downloadServices
				.handleDownloadSampleFile(sampleFileUrl)
				.catch((error) => {
					console.error("Download sample file failed ::", error);
				})
				.finally(() => {
					setShowSampleFileDownloadLoader(false);
				});
		}
	}

	// function handleUploadButtonClick() {
	// 	if (!fileInput) {
	// 		return;
	// 	}

	// 	onUploadButtonClick(fileInput);
	// }

	function handleEditRowDetails(newRow: GridRowModel<CombinedRowType>) {
		setRowsData((currentRowsData) =>
			handleProcessEditedRowDetails(
				newRow,
				[...currentRowsData],
				listsData,
				excelValidations ?? DEFAULT_VALIDATIONS,
				customFlatSelectionEnabled
			)
		);

		return newRow;
	}

	function handleChangeCustomFlatSelectionEnabled(isChecked: boolean) {
		setCustomFlatSelectionEnabled(isChecked);
	}

	function handleOpenCancelConfirmationDialog() {
		setShowCancelConfirmationDialog(true);
	}

	function handleCloseCancelConfirmationDialog() {
		setShowCancelConfirmationDialog(false);
	}

	function handleCloseDialog() {
		onClose();
		handleCloseCancelConfirmationDialog();
	}

	function handleDownloadButtonClick() {
		validationTableRef.current.exportDataAsExcel({
			fileName: "Incorrect_Entries",
			getRowsToExport: (params) => {
				const exportRowIds: GridRowId[] = [];

				for (const rowId of params.apiRef.current.getAllRowIds()) {
					const rowDetails = params.apiRef.current.getRow(rowId) as CombinedRowType;
					if (rowDetails.errorFields && Object.keys(rowDetails.errorFields).length > 0) {
						exportRowIds.push(rowId);
					}
				}
				return exportRowIds;
			}
		});
	}

	const revalidateRows = useCallback(() => {
		const updatedData = handleRevalidateRows(
			rowsData,
			columnsData,
			listsData,
			excelValidations ?? DEFAULT_VALIDATIONS,
			customFlatSelectionEnabled,
			handleOpenErrorDialog,
			handleOpenColumnFilter
		);
		setRowsData(updatedData.rows);
		setColumnsData(updatedData.columns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFlatSelectionEnabled, listsData]);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		open: openFileInput
	} = useDropzone({
		accept: {
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx", ".xls"],
			"application/vnd.ms-excel": [".xlsx", ".xls"]
		},
		useFsAccessApi: false,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles && acceptedFiles[0]) {
				handleExcelFileUpload(acceptedFiles[0]);
			}
		}
	});

	function handleBrowserWindowBeforeCloseEvent(event: BeforeUnloadEvent) {
		event.preventDefault();
		event.returnValue = true;
		return event;
	}

	const addWindowEventListeners = useCallback(() => {
		window.onbeforeunload = handleBrowserWindowBeforeCloseEvent;
	}, []);

	function removeWindowEventListeners() {
		window.onbeforeunload = null;
	}

	useEffect(() => {
		if (open) {
			setFileInput(null);
			setColumnsData([]);
			setRowsData([]);
			setSelectedFilter("all");
			setCurrentStep(UPLOAD_EXCEL_STEP_E.UPLOAD);
			setShowInvalidExcelError(false);
			setCustomFlatSelectionEnabled(false);
			setSelectedDevices(devicesList.map((item) => item.id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		revalidateRows();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFlatSelectionEnabled]);

	useEffect(() => {
		if (open && currentStep !== UPLOAD_EXCEL_STEP_E.UPLOAD) {
			addWindowEventListeners();
		} else {
			removeWindowEventListeners();
		}

		return () => {
			removeWindowEventListeners();
		};
	}, [addWindowEventListeners, currentStep, open]);

	const StepRenderer: StepRendererType = {
		[UPLOAD_EXCEL_STEP_E.UPLOAD]: (
			<>
				<Box className="upload-data-drop-area-wrapper" {...getRootProps()}>
					<FileUploadIcon color="primary" sx={{ fontSize: "70px" }} />
					<Typography variant="h5" sx={{ fontWeight: 500 }}>
						{isDragActive ? "Drop here to upload" : "Drag and drop files"}
					</Typography>

					<Box>
						<input
							{...getInputProps()}
							type="file"
							className="d-none"
							name="xslxFile"
							id="xslxFile"
							accept=".xlsx, .xls"
							onChange={(event) => {
								if (event.target.files && event.target.files[0]) {
									handleExcelFileUpload(event.target.files[0]);
								}
							}}
						/>
					</Box>
				</Box>

				{fileInput ? (
					<Box sx={{ marginTop: 2, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
						<Chip label={fileInput.name} onDelete={handleRemoveFile} />
					</Box>
				) : null}

				{showInvalidExcelError ? (
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 2 }}>
						<Typography variant="body2" color="var(--color-error-main)">
							The selected file is invalid.
						</Typography>
					</Box>
				) : null}

				<Box className="upload-data-actions-wrapper">
					<LoadingButton
						fullWidth
						variant="outlined"
						color="success"
						startIcon={<DownloadIcon />}
						onClick={handleDownloadSampleFile}
						loading={showSampleFileDownloadLoader}
						loadingPosition="start"
					>
						Download Sample File
					</LoadingButton>

					<LoadingButton
						fullWidth
						variant="contained"
						color="success"
						startIcon={<FileUploadIcon />}
						// onClick={handleUploadButtonClick}
						onClick={() => openFileInput()}
						loading={uploadButtonLoading || showUploadButtonLoading}
						loadingPosition="start"
					>
						Upload
					</LoadingButton>
				</Box>
			</>
		),
		[UPLOAD_EXCEL_STEP_E.VALIDATION]: (
			<>
				<Box sx={{ marginBottom: "1rem" }}>
					<Grid container spacing={2} columns={10}>
						<Grid item xs={12} md={2}>
							<Box className="upload-summary-card">
								<Box className="upload-summary-card-icon">
									<ContactsIcon sx={{ fontSize: "28px" }} />
								</Box>

								<Box className="upload-summary-card-text">
									<Typography color="#00000080" fontSize="14px">
										Total Entries
									</Typography>

									<Typography fontWeight={500}>{UploadedDataMetrics.totalEntries}</Typography>
								</Box>
							</Box>
						</Grid>

						<Grid item xs={12} md={2}>
							<Box className="upload-summary-card">
								<Box className="upload-summary-card-icon">
									<SupervisedUserCircleIcon sx={{ fontSize: "30px" }} />
								</Box>

								<Box className="upload-summary-card-text">
									<Typography color="#00000080" fontSize="14px">
										Identical Skipped
									</Typography>

									<Typography fontWeight={500}>{skippedRowsData.length}</Typography>
								</Box>
							</Box>
						</Grid>

						<Grid item xs={12} md={2}>
							<Box className="upload-summary-card success">
								<Box className="upload-summary-card-icon">
									<FactCheckIcon sx={{ fontSize: "30px" }} />
								</Box>

								<Box className="upload-summary-card-text">
									<Typography color="#00000080" fontSize="14px">
										Correct Entries
									</Typography>

									<Typography fontWeight={500}>{UploadedDataMetrics.correctEntries}</Typography>
								</Box>
							</Box>
						</Grid>

						<Grid item xs={12} md={2}>
							<Box className="upload-summary-card error">
								<Box className="upload-summary-card-icon">
									<IncorrectEntriesIcon width={30} fill="inherit" />
								</Box>

								<Box className="upload-summary-card-text">
									<Typography color="#00000080" fontSize="14px">
										Incorrect Entries
									</Typography>

									<Typography fontWeight={500}>{UploadedDataMetrics.incorrectEntries}</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box className="uploaded-data-header">
					<Box className="uploaded-data-filters-wrapper">
						<Box
							component="div"
							className={`data-filter-button ${selectedFilter}`}
							id="filter-dropdown-button"
							aria-controls={filterDropdownOpen ? "filter-dropdown-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={filterDropdownOpen}
							onClick={handleOpenFilterDropdown}
						>
							<Box className="data-filter-button-content">
								{selectedFilter === "all" ? (
									<ContactsIcon fontSize="small" />
								) : selectedFilter === "correct" ? (
									<FactCheckIcon fontSize="small" />
								) : selectedFilter === "incorrect" ? (
									<IncorrectEntriesIcon width={18} />
								) : null}

								<Typography variant="button">
									{selectedFilter === "all"
										? "All Data"
										: selectedFilter === "correct"
										? "Correct Data"
										: selectedFilter === "incorrect"
										? "Incorrect Data"
										: ""}
								</Typography>
							</Box>

							<Box className="data-filter-button-icon">
								<ArrowDropDownIcon fontSize="small" />
							</Box>
						</Box>

						<Menu
							id="filter-dropdown-menu"
							anchorEl={filterDropdownAnchorElement}
							open={filterDropdownOpen}
							onClose={handleCloseFilterDropdown}
							MenuListProps={{ disablePadding: true, "aria-labelledby": "filter-dropdown-button" }}
							sx={{ width: "100%" }}
						>
							<MenuItem
								onClick={() => handleChangeSelectedFilter("all")}
								sx={{ minWidth: "calc(175px - 0.5rem)" }}
								classes={{ root: "uploaded-data-filter-menu-item" }}
							>
								<ListItemIcon classes={{ root: "uploaded-data-filter-menu-item-icon" }}>
									<ContactsIcon />
								</ListItemIcon>
								All Data
							</MenuItem>

							<MenuItem
								onClick={() => handleChangeSelectedFilter("correct")}
								classes={{ root: "uploaded-data-filter-menu-item success" }}
							>
								<ListItemIcon classes={{ root: "uploaded-data-filter-menu-item-icon" }}>
									<FactCheckIcon />
								</ListItemIcon>
								Correct Data
							</MenuItem>

							<MenuItem
								onClick={() => handleChangeSelectedFilter("incorrect")}
								classes={{ root: "uploaded-data-filter-menu-item error" }}
							>
								<ListItemIcon classes={{ root: "uploaded-data-filter-menu-item-icon" }}>
									<IncorrectEntriesIcon />
								</ListItemIcon>
								Incorrect Data
							</MenuItem>
						</Menu>

						<Autocomplete
							multiple
							options={devicesList}
							getOptionLabel={(option) => option.device_name}
							getOptionKey={(option) => option.id}
							disableCloseOnSelect
							limitTags={1}
							value={devicesList.filter((item) => selectedDevices.includes(item.id))}
							onChange={(_, updatedValues) => handleChangeSelectedDevices(updatedValues.map((item) => item.id))}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ gap: "0.5rem" }}>
									{selected ? (
										<CheckBoxIcon color="primary" sx={{ marginY: "0.25rem" }} />
									) : (
										<CheckBoxOutlineBlankIcon color="primary" sx={{ marginY: "0.25rem" }} />
									)}

									{option.device_name}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									size="small"
									placeholder="Select devices"
									sx={{ width: "300px" }}
									error={selectedDevices.length === 0}
									helperText={selectedDevices.length === 0 ? "At least one device should be selected" : ""}
									FormHelperTextProps={{
										sx: { position: "absolute", top: "100%" }
									}}
								/>
							)}
							renderTags={() => <></>}
							PaperComponent={(props) => {
								const { children, ...restProps } = props;
								return (
									<Paper {...restProps}>
										<Box
											onMouseDown={(event) => event.preventDefault()}
											sx={{ paddingX: "1.25rem", paddingY: "0.25rem", width: "100%" }}
										>
											<FormControlLabel
												label="Select All"
												sx={{ width: "100%" }}
												control={
													<Checkbox
														checked={AllDevicesSelected === "all"}
														indeterminate={AllDevicesSelected === "partial"}
													/>
												}
												onClick={(event) => {
													event.preventDefault();
													if (AllDevicesSelected === "none" || AllDevicesSelected === "partial") {
														handleChangeSelectedDevices(devicesList.map((item) => item.id));
													} else if (AllDevicesSelected === "all") {
														handleChangeSelectedDevices([]);
													}
												}}
											/>
										</Box>

										<Divider />

										{children}
									</Paper>
								);
							}}
						/>

						<Typography>
							{selectedDevices.length === devicesList.length
								? "All devices selected"
								: selectedDevices.length === 0
								? "No devices selected"
								: `${selectedDevices.length} device${selectedDevices.length !== 1 ? "s" : ""} selected`}
						</Typography>
					</Box>

					<Box className="uploaded-data-actions-wrapper">
						{/* <Button variant="outlined" color="primary" size="small" startIcon={<FileUploadIcon />}>
							Upload
						</Button> */}

						{!disableCustomFlatNumber ? (
							<FormControlLabel
								control={
									<Checkbox
										checked={customFlatSelectionEnabled}
										onChange={(_, checked) => handleChangeCustomFlatSelectionEnabled(checked)}
									/>
								}
								label="Custom Flat Number"
							/>
						) : null}

						<Button
							disableElevation
							variant="contained"
							color="primary"
							size="small"
							startIcon={<FileDownloadIcon />}
							onClick={handleDownloadButtonClick}
						>
							Download Incorrect Entries
						</Button>
					</Box>
				</Box>

				<StyledBox ref={validationTableWrapperRef}>
					<DataGridPremium
						pagination
						columns={getModifiedColumnsData()}
						rows={FilteredRowsData}
						disableColumnFilter
						disableColumnMenu
						processRowUpdate={handleEditRowDetails}
						getRowClassName={(params: GridRowClassNameParams<CombinedRowType>) =>
							params.row.errorFields && Object.keys(params.row.errorFields).length > 0 ? "row-input-error" : ""
						}
						classes={{
							columnSeparator: "upload-data-validation-table-column-separator",
							columnHeader: "upload-data-validation-table-column-header",
							columnHeaderTitleContainer: "fullwidth"
						}}
						onPaginationModelChange={() => {
							if (validationTableWrapperRef.current) {
								validationTableWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
							}
						}}
						apiRef={validationTableRef}
						filterModel={{ items: columnFilters, logicOperator: GridLogicOperator.And }}
						editMode="row"
						rowModesModel={rowModesModel}
						onRowModesModelChange={handleRowModesModelChange}
					/>
				</StyledBox>

				<Box sx={{ marginTop: "1rem", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						endIcon={<ChevronRightIcon />}
						onClick={handleNextStep}
						// disabled={UploadedDataMetrics.incorrectEntries > 0}
						loading={uploadButtonLoading}
						loadingPosition="end"
						disabled={UploadedDataMetrics.correctEntries === 0 || selectedDevices.length === 0}
					>
						Proceed with Correct Data
					</LoadingButton>
				</Box>
			</>
		),
		[UPLOAD_EXCEL_STEP_E.AMBIGUITY_EXCEL]: (
			<>
				<Box sx={{ marginBottom: "1rem" }}>
					<Grid container spacing={2} columns={10}>
						<Grid item xs={12} md={2}>
							<Box className="upload-summary-card">
								<Box className="upload-summary-card-icon">
									<ContactsIcon sx={{ fontSize: "28px" }} />
								</Box>

								<Box className="upload-summary-card-text">
									<Typography color="#00000080" fontSize="14px">
										Similar Duplicates
									</Typography>

									<Typography fontWeight={500}>{DuplicateRows.length}</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box className="uploaded-data-header">
					<Box className="uploaded-data-filters-wrapper">
						<Box className="data-filter-button">
							<Box className="data-filter-button-content">
								<DuplicateIcon width={24} fill="white" />

								<Typography variant="button">All Duplicates</Typography>
							</Box>

							<Box className="data-filter-button-icon">
								<ArrowDropDownIcon fontSize="small" />
							</Box>
						</Box>
					</Box>
				</Box>

				<StyledBox ref={ambiguityTableRef}>
					<DataGridPremium
						pagination
						columns={getModifiedColumnsData(true).map((item) => ({ ...item, editable: false }))}
						rows={DuplicateRows}
						disableColumnFilter
						disableColumnMenu
						getRowClassName={(params: GridRowClassNameParams<CombinedRowType>) =>
							params.row.errorFields && Object.keys(params.row.errorFields).length > 0 ? "row-input-error" : ""
						}
						classes={{
							columnSeparator: "upload-data-validation-table-column-separator",
							columnHeader: "upload-data-validation-table-column-header"
						}}
						onPaginationModelChange={() => {
							if (ambiguityTableRef.current) {
								ambiguityTableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
							}
						}}
						onRowEditStart={(params) => handleEditButtonClick(params.id as number)}
						onRowEditCommit={(rowId) => handleSaveButtonClick(rowId as number)}
					/>
				</StyledBox>

				<Box sx={{ marginTop: "1rem", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1rem" }}>
					<Button variant="outlined" color="error" startIcon={<ChevronLeftIcon />} onClick={handlePreviousStep}>
						Back
					</Button>

					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						endIcon={<ChevronRightIcon />}
						onClick={handleNextStep}
						loading={uploadButtonLoading}
						loadingPosition="end"
					>
						Proceed with Correct Data
					</LoadingButton>
				</Box>
			</>
		),
		[UPLOAD_EXCEL_STEP_E.AMBIGUITY_DB]: (
			<>
				<Box sx={{ marginBottom: "1rem" }}>
					<Grid container spacing={2} columns={10}>
						<Grid item xs={12} md={2}>
							<Box className="upload-summary-card">
								<Box className="upload-summary-card-icon">
									<ContactsIcon sx={{ fontSize: "28px" }} />
								</Box>

								<Box className="upload-summary-card-text">
									<Typography color="#00000080" fontSize="14px">
										Similar Duplicates
									</Typography>

									<Typography fontWeight={500}>{rowsData.length}</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box className="uploaded-data-header">
					<Box className="uploaded-data-filters-wrapper">
						<Box className="data-filter-button">
							<Box className="data-filter-button-content">
								<DuplicateIcon width={24} fill="white" />

								<Typography variant="button">All Duplicates</Typography>
							</Box>

							<Box className="data-filter-button-icon">
								<ArrowDropDownIcon fontSize="small" />
							</Box>
						</Box>
					</Box>
				</Box>

				<StyledBox ref={dbAmbiguityTableRef}>
					<DataGridPremium
						pagination
						columns={getModifiedColumnsData(true)
							.filter((item) => item.field !== "issues")
							.map((item) => ({ ...item, editable: false }))}
						rows={rowsData}
						disableColumnFilter
						disableColumnMenu
						getRowClassName={(params: GridRowClassNameParams<CombinedRowType>) =>
							params.row.errorFields && Object.keys(params.row.errorFields).length > 0 ? "row-input-error" : ""
						}
						classes={{
							columnSeparator: "upload-data-validation-table-column-separator",
							columnHeader: "upload-data-validation-table-column-header"
						}}
						onPaginationModelChange={() => {
							if (dbAmbiguityTableRef.current) {
								dbAmbiguityTableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
							}
						}}
					/>
				</StyledBox>

				<Box sx={{ marginTop: "1rem", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1rem" }}>
					<Button variant="outlined" color="error" startIcon={<ChevronLeftIcon />} onClick={handlePreviousStep}>
						Back
					</Button>

					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						endIcon={<ChevronRightIcon />}
						onClick={handleNextStep}
						loading={uploadButtonLoading}
						loadingPosition="end"
					>
						Proceed with Correct Data
					</LoadingButton>
				</Box>
			</>
		)
	};

	return (
		<>
			<Dialog
				open={open}
				// onClose={onClose}
				fullWidth
				maxWidth={currentStep === UPLOAD_EXCEL_STEP_E.UPLOAD ? "md" : false}
				disableEscapeKeyDown
			>
				<DialogTitle className="dialog-title-root primary" textAlign="center">
					Upload File
					<Box className="close-dialog-icon-wrapper">
						<IconButton
							onClick={
								currentStep === UPLOAD_EXCEL_STEP_E.UPLOAD ? handleCloseDialog : handleOpenCancelConfirmationDialog
							}
						>
							<CloseIcon sx={{ color: "white" }} />
						</IconButton>
					</Box>
				</DialogTitle>

				<DialogContent classes={{ root: "dialog-content-root upload-data-dialog-content-root" }}>
					{StepRenderer[currentStep]}
				</DialogContent>
			</Dialog>

			<Dialog open={!!errorDialogMessage} onClose={handleCloseErrorDialog} fullWidth maxWidth="sm">
				<DialogTitle textAlign="center" classes={{ root: "dialog-title-root error" }}>
					Issue
					<Box className="close-dialog-icon-wrapper">
						<IconButton color="inherit" onClick={handleCloseErrorDialog}>
							<CloseIcon color="inherit" />
						</IconButton>
					</Box>
				</DialogTitle>

				<DialogContent classes={{ root: "dialog-content-root" }}>
					<Typography variant="h6" textAlign="center">
						{errorDialogMessage}
					</Typography>
				</DialogContent>
			</Dialog>

			<ConfirmationDialog
				open={showCancelConfirmationDialog}
				title="Cancel Upload Data"
				heading="Are you sure you want to cancel data upload?"
				warning="All the changes you made will be lost"
				onClose={handleCloseCancelConfirmationDialog}
				onConfirm={handleCloseDialog}
				width="sm"
				color="error"
			/>

			<Popover open={columnFilterOpen} anchorEl={columnFilterAnchorElement} onClose={handleCloseColumnFilter}>
				<Box
					component="form"
					noValidate
					onSubmit={handleSearchButtonClick}
					sx={{ padding: "1rem", display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}
				>
					<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						<Typography fontWeight={500}>{`Search ${columnFilterName}`}</Typography>

						{!customFlatSelectionEnabled && columnFilterName === "Flat Number" ? (
							<Autocomplete
								options={flatsList ?? []}
								value={columnFilterInput}
								onChange={(_, updatedValue) => handleChangeColumnFilterInput(updatedValue ?? "")}
								renderInput={(params) => (
									<TextField {...params} size="small" variant="outlined" color="primary" placeholder="Search" />
								)}
								sx={{ width: "200px" }}
							/>
						) : (
							<TextField
								autoFocus
								size="small"
								variant="outlined"
								color="primary"
								placeholder="Search"
								autoComplete="old-password"
								value={columnFilterInput}
								onChange={(event) => handleChangeColumnFilterInput(event.target.value)}
							/>
						)}
					</Box>

					<Button disableElevation variant="contained" color="primary" type="submit">
						Search
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default NewUploadDataDialog;
